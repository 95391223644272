import dna1 from '../../../assets/season-2/inter-dimentionals/dna1.svg';
import dna2 from '../../../assets/season-2/inter-dimentionals/dna2.svg';
import dna3 from '../../../assets/season-2/inter-dimentionals/dna3.svg';
import dna4 from '../../../assets/season-2/inter-dimentionals/dna4.svg';

import id0 from '../../../assets/season-2/inter-dimentionals/0.gif';
import id1 from '../../../assets/season-2/inter-dimentionals/1.gif';
import id2 from '../../../assets/season-2/inter-dimentionals/2.gif';
import id3 from '../../../assets/season-2/inter-dimentionals/3.gif';
import id4 from '../../../assets/season-2/inter-dimentionals/4.gif';
import id5 from '../../../assets/season-2/inter-dimentionals/5.gif';
import id6 from '../../../assets/season-2/inter-dimentionals/6.gif';
import id7 from '../../../assets/season-2/inter-dimentionals/7.gif';
import id8 from '../../../assets/season-2/inter-dimentionals/8.gif';
import id9 from '../../../assets/season-2/inter-dimentionals/9.gif';
import id10 from '../../../assets/season-2/inter-dimentionals/10.gif';

import AQUA from '../../../assets/season-2/inter-dimentionals/skins/AQUA.png'
import BLUE from '../../../assets/season-2/inter-dimentionals/skins/BLUE.png'
import GREEN from '../../../assets/season-2/inter-dimentionals/skins/GREEN.png'
import GREY from '../../../assets/season-2/inter-dimentionals/skins/GREY.png'
import LILAC from '../../../assets/season-2/inter-dimentionals/skins/LILAC.png'
import PALE from '../../../assets/season-2/inter-dimentionals/skins/PALE.png'
import ORANGE from '../../../assets/season-2/inter-dimentionals/skins/ORANGE.png'
import PEACH from '../../../assets/season-2/inter-dimentionals/skins/PEACH.png'
import PINK from '../../../assets/season-2/inter-dimentionals/skins/PINK.png'
import RED from '../../../assets/season-2/inter-dimentionals/skins/RED.png'
import GOLD from '../../../assets/season-2/inter-dimentionals/skins/GOLD.png'
import HELLYEAH from '../../../assets/season-2/inter-dimentionals/skins/HELLYEAH.png'
import RAINBOW from '../../../assets/season-2/inter-dimentionals/skins/RAINBOW.png'
import FRUITY from '../../../assets/season-2/inter-dimentionals/skins/FRUITY.png'
import VOLT from '../../../assets/season-2/inter-dimentionals/skins/VOLT.png'
import YELLOW from '../../../assets/season-2/inter-dimentionals/skins/YELLOW.png'

import AQUA_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/AQUA_CLOSED.png'
import AQUA_OPENED from '../../../assets/season-2/inter-dimentionals/skins/AQUA_OPENED.png'
import BLUE_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/BLUE_CLOSED.png'
import BLUE_OPENED from '../../../assets/season-2/inter-dimentionals/skins/BLUE_OPENED.png'
import GREEN_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/GREEN_CLOSED.png'
import GREEN_OPENED from '../../../assets/season-2/inter-dimentionals/skins/GREEN_OPENED.png'
import GREY_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/GREY_CLOSED.png'
import GREY_OPENED from '../../../assets/season-2/inter-dimentionals/skins/GREY_OPENED.png'
import LILAC_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/LILAC_CLOSED.png'
import LILAC_OPENED from '../../../assets/season-2/inter-dimentionals/skins/LILAC_OPENED.png'
import PALE_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/PALE_CLOSED.png'
import PALE_OPENED from '../../../assets/season-2/inter-dimentionals/skins/PALE_OPENED.png'
import ORANGE_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/ORANGE_CLOSED.png'
import ORANGE_OPENED from '../../../assets/season-2/inter-dimentionals/skins/ORANGE_OPENED.png'
import PEACH_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/PEACH_CLOSED.png'
import PEACH_OPENED from '../../../assets/season-2/inter-dimentionals/skins/PEACH_OPENED.png'
import PINK_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/PINK_CLOSED.png'
import PINK_OPENED from '../../../assets/season-2/inter-dimentionals/skins/PINK_OPENED.png'
import RED_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/RED_CLOSED.png'
import RED_OPENED from '../../../assets/season-2/inter-dimentionals/skins/RED_OPENED.png'
import GOLD_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/GOLD_CLOSED.png'
import GOLD_OPENED from '../../../assets/season-2/inter-dimentionals/skins/GOLD_OPENED.png'
import HELLYEAH_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/HELLYEAH_CLOSED.png'
import HELLYEAH_OPENED from '../../../assets/season-2/inter-dimentionals/skins/HELLYEAH_OPENED.png'
import RAINBOW_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/RAINBOW_CLOSED.png'
import RAINBOW_OPENED from '../../../assets/season-2/inter-dimentionals/skins/RAINBOW_OPENED.png'
import FRUITY_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/FRUITY_CLOSED.png'
import FRUITY_OPENED from '../../../assets/season-2/inter-dimentionals/skins/FRUITY_OPENED.png'
import VOLT_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/VOLT_CLOSED.png'
import VOLT_OPENED from '../../../assets/season-2/inter-dimentionals/skins/VOLT_OPENED.png'
import YELLOW_CLOSET from '../../../assets/season-2/inter-dimentionals/skins/YELLOW_CLOSED.png'
import YELLOW_OPENED from '../../../assets/season-2/inter-dimentionals/skins/YELLOW_OPENED.png'

import greyGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/greyGunhand.png'
import greyHand from '../../../assets/season-2/inter-dimentionals/skins/hands/greyHand.png'
import paleGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/paleGunhand.png'
import paleHand from '../../../assets/season-2/inter-dimentionals/skins/hands/paleHand.png'
import greenGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/greenGunhand.png'
import greenHand from '../../../assets/season-2/inter-dimentionals/skins/hands/greenHand.png'
import lilacGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/lilacGunhand.png'
import lilacHand from '../../../assets/season-2/inter-dimentionals/skins/hands/lilacHand.png'
import peachGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/peachGunhand.png'
import peachHand from '../../../assets/season-2/inter-dimentionals/skins/hands/peachHand.png'
import yellowGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/yellowGunhand.png'
import yellowHand from '../../../assets/season-2/inter-dimentionals/skins/hands/yellowHand.png'
import orangeGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/orangeGunhand.png'
import orangeHand from '../../../assets/season-2/inter-dimentionals/skins/hands/orangeHand.png'
import redGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/redGunhand.png'
import redHand from '../../../assets/season-2/inter-dimentionals/skins/hands/redHand.png'
import aquaGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/aquaGunhand.png'
import aquaHand from '../../../assets/season-2/inter-dimentionals/skins/hands/aquaHand.png'
import pinkGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/pinkGunhand.png'
import pinkHand from '../../../assets/season-2/inter-dimentionals/skins/hands/pinkHand.png'
import blueGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/blueGunhand.png'
import blueHand from '../../../assets/season-2/inter-dimentionals/skins/hands/blueHand.png'
import rainbowGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/rainbowGunhand.png'
import rainbowHand from '../../../assets/season-2/inter-dimentionals/skins/hands/rainbowHand.png'
import goldGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/goldGunhand.png'
import goldHand from '../../../assets/season-2/inter-dimentionals/skins/hands/goldHand.png'
import fruityGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/fruityGunhand.png'
import fruityHand from '../../../assets/season-2/inter-dimentionals/skins/hands/fruityHand.png'
import hellyeahGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/hellyeahGunhand.png'
import hellyeahHand from '../../../assets/season-2/inter-dimentionals/skins/hands/hellyeahHand.png'
import voltGunhand from '../../../assets/season-2/inter-dimentionals/skins/hands/voltGunhand.png'
import voltHand from '../../../assets/season-2/inter-dimentionals/skins/hands/voltHand.png'

import aquaArm from '../../../assets/season-2/inter-dimentionals/skins/arms/aquaArm.png'
import blueArm from '../../../assets/season-2/inter-dimentionals/skins/arms/blueArm.png'
import goldArm from '../../../assets/season-2/inter-dimentionals/skins/arms/goldArm.png'
import greenArm from '../../../assets/season-2/inter-dimentionals/skins/arms/greenArm.png'
import greyArm from '../../../assets/season-2/inter-dimentionals/skins/arms/greyArm.png'
import hellyeahArm from '../../../assets/season-2/inter-dimentionals/skins/arms/hellyeahArm.png'
import lilacArm from '../../../assets/season-2/inter-dimentionals/skins/arms/lilacArm.png'
import orangeArm from '../../../assets/season-2/inter-dimentionals/skins/arms/orangeArm.png'
import paleArm from '../../../assets/season-2/inter-dimentionals/skins/arms/paleArm.png'
import peachArm from '../../../assets/season-2/inter-dimentionals/skins/arms/peachArm.png'
import pinkArm from '../../../assets/season-2/inter-dimentionals/skins/arms/pinkArm.png'
import rainbowArm from '../../../assets/season-2/inter-dimentionals/skins/arms/rainbowArm.png'
import redArm from '../../../assets/season-2/inter-dimentionals/skins/arms/redArm.png'
import toxicArm from '../../../assets/season-2/inter-dimentionals/skins/arms/toxicArm.png'
import voltArm from '../../../assets/season-2/inter-dimentionals/skins/arms/voltArm.png'
import yellowArm from '../../../assets/season-2/inter-dimentionals/skins/arms/yellowArm.png'


export const dna = [id0,id1,id2,id3,id4,id5,id6,id7,id8,id9,id10];
export const dnaLevels = [0,5,15,35,85,185,385,1635,4135,9135,19135];
export const gunHands = [greyGunhand,paleGunhand,greenGunhand,lilacGunhand,peachGunhand,yellowGunhand,orangeGunhand,redGunhand,aquaGunhand,pinkGunhand,blueGunhand,fruityGunhand,voltGunhand,hellyeahGunhand,rainbowGunhand,goldGunhand];
export const hands = [greyHand,paleHand,greenHand,lilacHand,peachHand,yellowHand,orangeHand,redHand,aquaHand,pinkHand,blueHand,fruityHand,voltHand,hellyeahHand,rainbowHand,goldHand];
export const arms = [greyArm,paleArm,greenArm,lilacArm,peachArm,yellowArm,orangeArm,redArm,aquaArm,pinkArm,blueArm,toxicArm,voltArm,hellyeahArm,rainbowArm,goldArm]
export const skinsClosed = [
  GREY_CLOSET,
  PALE_CLOSET,
  GREEN_CLOSET,
  LILAC_CLOSET,
  PEACH_CLOSET,
  YELLOW_CLOSET,
  ORANGE_CLOSET,
  RED_CLOSET,
  AQUA_CLOSET,
  PINK_CLOSET,
  BLUE_CLOSET,
  FRUITY_CLOSET,
  VOLT_CLOSET,
  HELLYEAH_CLOSET,
  RAINBOW_CLOSET,
  GOLD_CLOSET
]
export const skinsOpened = [
  GREY_OPENED,
  PALE_OPENED,
  GREEN_OPENED,
  LILAC_OPENED,
  PEACH_OPENED,
  YELLOW_OPENED,
  ORANGE_OPENED,
  RED_OPENED,
  AQUA_OPENED,
  PINK_OPENED,
  BLUE_OPENED,
  FRUITY_OPENED,
  VOLT_OPENED,
  HELLYEAH_OPENED,
  RAINBOW_OPENED,
  GOLD_OPENED
]


export const SKIN_LEVELS = {
  'GREY': 0,
  'GREEN': 0,
  'LIME GREEN': 6,
  'LILAC': 6,
  'PEACH': 6,
  'YELLOW': 7,
  'ORANGE': 7,
  'RED': 7,
  'AQUA': 8,
  'PINK': 8,
  'BLUE': 8,
  'FRUITY': 9,
  'VOLT': 9,
  'HELL YEH': 9,
  'RAINBOW': 10,
  'GOLDEN': 10,
};

export const SKINS_SOURCES = {
  'GREY': GREY,
  'GREEN': PALE,
  'LIME GREEN': GREEN,
  'LILAC': LILAC,
  'PEACH': PEACH,
  'YELLOW': YELLOW,
  'ORANGE': ORANGE,
  'RED': RED,
  'AQUA': AQUA,
  'PINK': PINK,
  'BLUE': BLUE,
  'FRUITY': FRUITY,
  'VOLT': VOLT,
  'HELL YEH': HELLYEAH,
  'RAINBOW': RAINBOW,
  'GOLDEN': GOLD,
};

export const moleculesData = [
  {
    name: `Molecule A`,
    dna: dna1,
    positiveAction: `60% Chance `,
    isPosDna: true,
    negativeAction: `Adds 5 pts`,
    isNegDna: true,
    price: `5000`,
    popupCopy: <div>Unlimited use, degen time!</div>
  },
  {
    name: `Molecule B`,
    dna: dna2,
    positiveAction: `50% Chance `,
    isPosDna: true,
    negativeAction: `Adds 50 pts `,
    isNegDna: true,
    price: `5000`,
    popupCopy: <div>Molly B baby,<br />Don’t fuck it up!</div>
  },
  {
    name: `Molecule C`,
    dna: dna3,
    positiveAction: `40% Chance `,
    isPosDna: true,
    negativeAction: `1 time double BOOST `,
    isNegDna: false,
    price: `5000`,
    popupCopy: <div>If fail it locks all molecules! whoops...</div>
  },
  {
    name: `Molecule D`,
    dna: dna4,
    positiveAction: `20% Chance `,
    isPosDna: true,
    negativeAction: `Completes Level `,
    isNegDna: true,
    price: `5000`,
    popupCopy: <div>You need your ol’ invasion pass!</div>
  }
]

export const defaultTabs = [
  {
    text: `Enrich ID`,
    active: true,
    id: 0
  },
  {
    text: `Inventory`,
    active: false,
    id: 1,
    disabled: false
  },
  {
    text: `Packs`,
    active: false,
    id: 2,
    disabled: false
  },
  {
    text: `Visualiser`,
    active: false,
    id: 4,
    disabled: false
  },
  {
    text: `Marketplace`,
    active: false,
    id: 3,
    disabled: false
  },
  {
    text: `Evolve`,
    active: false,
    id: 5,
    disabled: true
  }
];

export const getSkinByLevel = (idLevel) => { 
  let skins = [];
  let counter = 0;
  for (let skin in SKIN_LEVELS) {
    if (idLevel >= SKIN_LEVELS[skin]) {
      skins.push({
        title: `${skin}`,
        isSelected: counter === 0 ? true : false,
        id: counter++,
        image: SKINS_SOURCES[skin],
        rarity: SKIN_LEVELS[skin] < 6 ? 1 : SKIN_LEVELS[skin] - 5,
      })
    }
  }
  return skins;
}

const baseBgs = [
  {
    title: 'Peppermint',
    color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Peppermint-min.png") center/contain no-repeat',
    rarity: 1
  },
  {
    title: 'Blue',
    color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Blue-min.png") center/contain no-repeat',
    rarity: 1
  },
  {
    title: 'Absinthe',
    color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Absinthe-min.png") center/contain no-repeat',
    rarity: 1
  },
  {
    title: 'Mustard',
    color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Mustard-min.png") center/contain no-repeat',
    rarity: 1
  },
  {
    title: 'Nebula',
    color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Nebula-min.png") center/contain no-repeat',
    rarity: 1
  },
]

const bgsByLevel = [
  [
    {
      title: 'Yellow',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Yellow-min.png") center/contain no-repeat'
    },
    {
      title: 'Watermelon',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Watermelon-min.png") center/contain no-repeat'
    },
    {
      title: 'Starburst',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Starburst-min.png") center/contain no-repeat'
    },
    {
      title: 'Peach',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Peach-min.png") center/contain no-repeat'
    },
    {
      title: 'Lemuria',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Lemuria-min.png") center/contain no-repeat'
    },
  ],
  [
    {
      title: 'Serenity',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Serenity-min.png") center/contain no-repeat'
    },
    {
      title: 'Clear-skies',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Clear-skies-min.png") center/contain no-repeat'
    },
    {
      title: 'Boring-Border',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Boring-Border-min.png") center/contain no-repeat'
    },
    {
      title: 'Vortex',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Vortex-min.png") center/contain no-repeat'
    },
    {
      title: 'Beach',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Beach-min.png") center/contain no-repeat'
    },
    
  ],
  [
    {
      title: 'Fire',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Fire-min.png") center/contain no-repeat'
    },
    {
      title: 'Pink',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Pink-min.png") center/contain no-repeat'
    },
    {
      title: 'Lilac',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Lilac-min.png") center/contain no-repeat'
    },  
    {
      title: 'Dusk',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Dusk-min.png") center/contain no-repeat'
    },
    {
      title: 'Checkers',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Checkers-min.png") center/contain no-repeat'
    },
    
  ],
  [


    {
      title: 'Swamp',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Swamp-min.png") center/contain no-repeat'
    },
    {
      title: 'Psychedelic',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Psychedelic-min.png") center/contain no-repeat'
    },
    {
      title: 'On-the-grid',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/On-the-grid-min.png") center/contain no-repeat'
    },
    {
      title: 'Mutations',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Mutations-min.png") center/contain no-repeat'
    },
    {
      title: 'Battlefield-Mist',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Battlefield-mist-min.png") center/contain no-repeat'
    },
  ],
  [
    {
      title: 'Swarm',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Swarm-min.png") center/contain no-repeat'
    },
    {
      title: 'Scribbler',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Scribbler-min.png") center/contain no-repeat'
    },

    {
      title: 'Swamp-gas',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Swamp-gas-min.png") center/contain no-repeat'
    },
    {
      title: 'Blood',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Blood-min.png") center/contain no-repeat'
    },
    {
      title: 'Gold',
      color: 'url("https://creepz-public.nyc3.digitaloceanspaces.com/Backgrounds/Gold-min.png") center/contain no-repeat'
    },

  ]
]


export const getBgByLevel = (idLevel) => {
  let backgrounds = [
    ...baseBgs
  ];
  if (idLevel > 5) {
    for (let i = 0; i <= (idLevel - 6); i++) {
      backgrounds.push(
        ...bgsByLevel[i].map(x => {
          return {
            ...x,
            rarity: i + 1
          }
        })
      );
    }
  } 
  console.log(`backgrounds`, backgrounds)
  return backgrounds;
}