import React, { useState } from 'react';
import {getMolecules} from '../plugins/dnaModel';

const MoleculesContext = React.createContext(null);
MoleculesContext.displayName = 'MoleculesContext';

const MoleculesProvider = ({ children }) => {
  const [data, setData] = useState([]);

  return (
    <MoleculesContext.Provider
      value={{
        data,
        updateMoleculeData: async () => {
          const molecules = await getMolecules();
          
          setData(molecules.data)
          
          return true;
        }
      }}
    >
      {children}
    </MoleculesContext.Provider>
  );
};

export { MoleculesProvider };
export default MoleculesContext;