import React from 'react'
import Marquee from "react-double-marquee/dist/bundle.esm"


const News = ({colorBackground, text, colorText, colorBorder, link, point}) => {

    const words = new Array(8).fill(Math.random())


    return (
        <div style={{
            backgroundColor: colorBackground,
            borderTop: `2px solid ${colorBorder}`,
            borderBottom: `2px solid ${colorBorder}`,
            color: colorText
        }}
             className='news'
        >
            <Marquee
                speed={0.05}
                direction='left'
            >
                {
                    words.map((item, index) => (
                      link ?
                        <a href="https://mintcalendar.substack.com/p/gm-frens-wagmi">
                          <span key={item + index}>
                              <span>
                                  {text.length === 1 ? text[0] : ''}
                                  {index % 2 === 0 && text.length > 1 ? text[0] : ''}
                                  {index % 2 === 1 && text.length > 1 ? text[1] : ''}
                              </span>
                          </span>
                        </a> :

                        <span key={item + index}>
                          <span>
                              {text.length === 1 ? text[0] : ''}
                              {index % 2 === 0 && text.length > 1 ? text[0] : ''}
                              {index % 2 === 1 && text.length > 1 ? text[1] : ''}
                          </span>
                          {point ? <div className="news__point"/> : ``}
                        </span>
                      
                    ))
                }
            </Marquee>
        </div>
    )
}

export default News
