import '../../scss/dashboard/style.scss';
import 'react-notifications/lib/notifications.css';
import { useState,useRef, useEffect, useContext } from 'react';
import {useEthers} from "@usedapp/core";
import {NotificationManager} from 'react-notifications';
import { getCurrentLamexTier } from '../../plugins/utils'
import { USER_CHAIN_DATA, getUserYield, getShardsYield } from '../../plugins/lamexModel'
import { useQuery } from "@apollo/client";

import ModalContext, {ModalProvider} from '../../context/ModalContext'

import Start from '../Dashboard/Start';
import Lamex from '../Dashboard/Lamex';
import InterDimentionals from '../Dashboard/InterDimentionals';
import ApplicationProcess from '../Dashboard/Lamex/ApplicationProcess';
import PortalOpening from '../Dashboard/PortalOpening';

import seasonTwoVideo from '../../assets/season-2/season-2.mp4';
import logoFile from '../../assets/intro/logo.svg';
import portalBg from '../../assets/season-2/portal/Portal_bg.json';

import {Config} from "../../config";
import {hasUserSwitchedToLamex} from "../../plugins/web3";
 
import UserContext from '../../context/UserContext';
import MoleculesContext from '../../context/MoleculesContext';
import Lottie from 'lottie-react';
import Button from '../Dashboard/Elements/Button';

const Dashboard = () => {

    const user = useContext(UserContext);
    const molecules = useContext(MoleculesContext);
    const lottieRef = useRef();

    const [currentState, setCurrentState] = useState("holder");
    const [videoModalActive, setVideoModalActive] = useState(false);
    const [isInterfaceLocked, setIsInterfaceLocked] = useState(true);
    const {activateBrowserWallet, account, chainId} = useEthers();
    const video = useRef(null);

    const zeroAccount = "0x0000000000000000000000000000000000000000";
    const { data, refetch } = useQuery(USER_CHAIN_DATA, {
      variables: { ownerId: account?.toLowerCase() || zeroAccount },
      pollInterval: 20000
    });

    const getSubgraphData = async () => {
      const creepz = data.owners[0].creeps.map(x => x.id);
      const arms = data.owners[0].armouries.map(x => x.id);
      const vaults = data.owners[0].vaults.map(x => x.id);
      const megas = data.owners[0].megaShapeShifters.map(x => x.id);
      const hasUserSwitched = await hasUserSwitchedToLamex(account);
      const lastShardsDeposit = data.owners[0].lastMegaStake * 1000;
      let totalYield;
      let shardsYield;
      let creepzYield;

      if (megas.length) {
        shardsYield = await getShardsYield(megas);
      }

      if (creepz.length) {
        const yieldData = await getUserYield(creepz,arms,vaults);
        creepzYield = yieldData.data.creepzRewards.reduce((prev, curr) => {
          return +prev + +curr;
        }, 0);
        totalYield = [...yieldData.data.armouryRewards, ...yieldData.data.creepzRewards, ...yieldData.data.vaultRewards].reduce((prev, curr) => {
          return +prev + +curr;
        }, 0);
      } else {
        totalYield = vaults.length * 0.5;
      }
      
      user.setGraphData({
        stakedCreepz: creepz,
        stakedArms: arms,
        stakedVaults: vaults,
        stakedMegas: megas,
        isYieldingLamex: hasUserSwitched,
        lamexTier: getCurrentLamexTier(creepz.length, hasUserSwitched),
        lamexYield: totalYield.toFixed(2),
        shardsYield: shardsYield?.data?.amount || 0,
        creepzYield: creepzYield > 0 ? creepzYield.toFixed(2) : 0,
        lastShardStakingReward: lastShardsDeposit
      })
    } 

    useEffect(() => {
      if (data) {
        if (!data.owners.length) {
          user.resetGraphData();
          return; 
        }
        getSubgraphData();
      }
    }, [data])

    useEffect(() => {
      if (videoModalActive) {
          document.body.style.overflow = 'hidden'
      } else {
          document.body.style.overflow = 'visible'
      }
    }, [videoModalActive]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
      if (chainId !== undefined && chainId !== Number(Config.targetChain)) {
        NotificationManager.warning(`Wrong network selected!`)
      }
    }, [chainId])

    const triggerVideoModal = (toOpen, e) => {
      const key = e?.key;
      if (key === undefined || key === 'Escape') {
        setVideoModalActive(toOpen);
        if (toOpen) video.current.play();
        if (!toOpen) video.current.pause();
      }
    }

    const closePopUp = (e) => {
      if (e.target.className.includes("dashboard-popup")) {
        setVideoModalActive(false);
        video.current.pause();
      }
    }

    const onError = () => {
      NotificationManager.error(`Metamask not installed!`)
    }

    const activateWallet = async () => {
      if (!account) {
        activateBrowserWallet(onError);
      }
    }

    const getUser = async () => {
      await user.updateUserData(account);
      await molecules.updateMoleculeData();
      setIsInterfaceLocked(false);
    }

    const switchBetweenStates = (newState, forcePush = false) => {
      if (newState === `ids` && !user.hasTransferedPoints) {
        NotificationManager.warning(`You need to open your LAMEX account first!`);
        return;
      }
      setCurrentState(newState);
      if (newState === `lamex` && !user.hasTransferedPoints || newState === `application`) setCurrentState(`application`);
      if (newState === `lamex` && (user.hasTransferedPoints || forcePush)) setCurrentState(`lamex`);
      if (newState === `ids` && user.hasOpenedPortal) setCurrentState(`idDashboard`);
      if (newState === `ids` && !user.hasOpenedPortal) setCurrentState(`portal`);
    }

    useEffect(() => {
      if (account) {
        getUser();
        refetch();
      }
    }, [account, chainId])

    const [width, setWidth] = useState(0);
    useEffect(() => {
      const width = window.innerWidth;
      setWidth(width);
    },[]);

    return (
      <>
        <div className={`dashboard-popup ${videoModalActive ? `active` : ``}`} onKeyDown={(e) => triggerVideoModal(false, e)} onClick={(e) => closePopUp(e)} tabIndex="0">
          <div className="video">
            <div className="logo">
              <img src={logoFile} alt="logo icon" />
            </div>
            <video ref={video} loop onClick={() => video.current.paused ? video.current.play() : video.current.pause()}>
              <source src={seasonTwoVideo} type="video/mp4"/>
            </video>
            <div onClick={() => triggerVideoModal(false)} className="popup__button-close">
              <div className="wrapper"/>
            </div>
          </div>
        </div>

        <div className="main-wrapper" style={{
            // background: currentState !== `portal` ? `none` : `url(${portalBg}) no-repeat`,
            backgroundPosition: `center`,
            backgroundSize: `contain`
          }}>

          {
            <>
              <div className="noise-wrapper">
                <div className="noise"></div>
              </div>

              <div className="holder-wrapper">
                <div className="holder">
                  <h2>Creepz & IDs migrated to Overlord</h2>
                  <p>Overlord is the new home of Creepz and IDs. All Creepz and IDs have been migrated to Overlord. You can now access your Creepz and IDs on Overlord.</p>
                  <div className="a"><a href="https://overlord.xyz" target="_blank" rel="noreferrer"><Button buttonText={`Go To Overlord`}/></a></div>
                </div>
              </div>
            </>
          }
            
          {/* {
            currentState !== `portal` ? (
              <div className="noise-wrapper">
                <div className="noise"></div>
              </div>
            ) : (
              <div className='lottie_bg'>
                <Lottie animationData={portalBg} loop={true} lottieRef={lottieRef} style={{
                  transform: `scale(${width > 700 ? `2` : `5.5`})`,
                  height: `100%`
                }}
                />
              </div>
            )
          } */}
{/*           
          <div className={`dashboard-container ${currentState === `portal` ? `portal-container` : ``}`}>
          {currentState === `start` ? 
              <Start 
                triggerVideoModal={triggerVideoModal} 
                account={account} 
                activateWallet={activateWallet}
                setCurrentState={switchBetweenStates}
                isInterfaceLocked={isInterfaceLocked}
              /> 
            : currentState === `lamex` ? 
            <Lamex 
              triggerVideoModal={triggerVideoModal}
              account={account} 
              activateWallet={activateWallet}
              setCurrentState={switchBetweenStates}
            />
            : currentState === `application` ? 
            <ApplicationProcess 
              triggerVideoModal={triggerVideoModal}
              account={account} 
              activateWallet={activateWallet}
              setCurrentState={switchBetweenStates}
            /> : currentState === `idDashboard` ? 
            <InterDimentionals 
              triggerVideoModal={triggerVideoModal}
              account={account} 
              activateWallet={activateWallet}
              setCurrentState={switchBetweenStates}
              screenWidth={width}
            /> 
            : <PortalOpening account={account} setCurrentState={switchBetweenStates} pause={() => lottieRef.current.pause()} start={() => lottieRef.current.play()}/>}
          </div> */}
        </div>
      </>
    )
}

export default Dashboard;
