import GunClip from '../../../assets/season-2/inter-dimentionals/packsItems/GunClip.jpg'
import GunMuzzle from '../../../assets/season-2/inter-dimentionals/packsItems/GunMuzzle.jpg'
import ArmDetail from '../../../assets/season-2/inter-dimentionals/packsItems/ArmDetailing.jpg'
import GunSight from '../../../assets/season-2/inter-dimentionals/packsItems/GunSight.jpg'
import GunTop from '../../../assets/season-2/inter-dimentionals/packsItems/GunTop.jpg'
import LowerGun from '../../../assets/season-2/inter-dimentionals/packsItems/LowerGun.jpg'
import Keychain from '../../../assets/season-2/inter-dimentionals/packsItems/Keychain.jpg'
import LeftEye from '../../../assets/season-2/inter-dimentionals/packsItems/LeftEye.jpg'
import Mouth from '../../../assets/season-2/inter-dimentionals/packsItems/Mouth.jpg'
import RightEye from '../../../assets/season-2/inter-dimentionals/packsItems/RightEye.jpg'
import ShardPack from '../../../assets/season-2/inter-dimentionals/packsItems/ShardPack.jpg'

import GunClipLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/GunClip.json'
import GunMuzzleLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/GunMuzzle.json'
import ArmDetailingLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/ArmDetailing.json'
import GunSightLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/GunSight.json'
import GunTopLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/GunTop.json'
import LowerGunLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/LowerGun.json'
import KeychainLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/Keychain.json'
import LeftEyeLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/LeftEye.json'
import MouthLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/Mouth.json'
import RightEyeLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/RightEye.json'
import ShardPackLottie from '../../../assets/season-2/inter-dimentionals/packsLottie/Shard.json'


export const packsImages = [
  ShardPack,
  LeftEye,
  RightEye,
  ArmDetail,
  Keychain,
  LowerGun,
  GunTop,
  GunMuzzle,
  GunSight,
  Mouth,
  GunClip
];

export const packslotties = [
  ShardPackLottie,
  LeftEyeLottie,
  RightEyeLottie,
  ArmDetailingLottie,
  KeychainLottie,
  LowerGunLottie,
  GunTopLottie,
  GunMuzzleLottie,
  GunSightLottie,
  MouthLottie,
  GunClipLottie
];
