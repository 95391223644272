
import Lottie from "lottie-react";

import gif1 from '../../assets/statistic/cards/cbc-spin-coin.json'


const Loader = () => {

    return (
        <section className='loader'>
            <div className="loader__container">
              <Lottie animationData={gif1} loop={true}/>
            </div>
        </section>
    )
}

export default Loader
