import axios from 'axios';
import * as Sentry from "@sentry/react";
import { Config } from '../config';


export const getWhitelists = async () => {
  try {
    const response = await axios.get(`${Config.apiLink}/whitelists/`)

    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const signUp = async (id, address, secondAddress, signature, nonce, value, discord) => {
  try {
    console.log(id, address, signature, nonce, value)
    const response = await axios({
      url: `${Config.apiLink}/whitelists/${id}/user-claim`,
      method: 'post',
      data: {
        address,
        secondAddress,
        nonce,
        value: +value,
        signature,
        discordUsername: discord
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      return err.response.data;
  }
}
