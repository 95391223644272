import { useEffect, useState } from "react"


const ScrollHandler = ({ setShouldDiscordType, setShouldDArmoryType, setScrollForPlan }) => {
  const [scrolled, setScrolled] = useState(0);
  const [scroll, setScroll] = useState(0);
  const scrollListener = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  
    const scrolled = winScroll / height
    setScroll(scrolled);
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollListener)
  }, []);

  useEffect(() => {
    // console.log(scroll)
    if (scroll > 0.195) {
      setShouldDArmoryType(true)
      window.removeEventListener('scroll', scrollListener)
    }
    if (scroll > 0.4) {
      setScrollForPlan(true);
    }
    if (scroll > 0.61) {
      setShouldDiscordType(true);
      window.removeEventListener('scroll', scrollListener)
    }
  }, [scroll]);

    return (<span></span>)
};

export default ScrollHandler
