export const Config = {
  contractAddress: process.env.REACT_APP_CREEPZ,
  stakingAddress: process.env.REACT_APP_STAKING,
  targetChain: +process.env.REACT_APP_TARGET_ID,
  armsAddress: process.env.REACT_APP_ARMOURY,
  shapeAddress: process.env.REACT_APP_SHAPE,
  pouchAddress: process.env.REACT_APP_POUCH,
  megaAddress: process.env.REACT_APP_MEGA,
  subgraphURI: process.env.REACT_APP_SUBGRAPH,
  subgraphLeadeboardURI: process.env.REACT_APP_LEADERBOARD_SUBGRAPH,
  infuraUri: process.env.REACT_APP_INFURA_URI,
  illiminartiAddress: process.env.REACT_APP_LOOMI,
  vaultStakingAddress: process.env.REACT_APP_VAULT_STAKING,
  mysteryAddress: process.env.REACT_APP_MYSTERY_BOX,
  apiLink: process.env.REACT_APP_API,
  tokenPrice: +process.env.REACT_APP_POUCH_PRICE,
  nameChange: process.env.REACT_APP_NAME_CHANGE,
  subgraphNames: process.env.REACT_APP_NAME_CHANGE_SUBGRAPH,
  shardStoreAddress: process.env.REACT_APP_SHARD_STORE,
  lamexAddress: process.env.REACT_APP_LAMEX,
  subgraphLamex: process.env.REACT_APP_LAMEX_SUBGRAPH,
  yieldSwitchAddress: process.env.REACT_APP_SWITCH_YIELD,
  claimUpdatePeriod: process.env.REACT_APP_CLAIM_PERIOD
}