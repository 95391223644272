import React, {useEffect, useState, Suspense, useContext} from "react"
import Main from "./components/Pages/Main";
import Dashboard from "./components/Pages/Dashboard";
import {NotificationContainer} from 'react-notifications';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import {Config} from './config';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {UserProvider} from "./context/UserContext"
import {MoleculesProvider} from "./context/MoleculesContext"
import { WhitelistsProvider } from "./context/WhitelistsContext";
import { ModalProvider } from "./context/ModalContext";
import { TraitsProvider } from "./context/TraitsContext";

const client = new ApolloClient({
  uri: Config.subgraphLamex,
  cache: new InMemoryCache()
});

function App() {


    return (
      <Router>
       <Switch>
          <Route path="/season-2">
            <WhitelistsProvider>
              <UserProvider>
                <MoleculesProvider>
                  <TraitsProvider>
                    <ModalProvider>
                      <ApolloProvider client={client}>
                        <DndProvider backend={HTML5Backend}>
                          <Dashboard />
                        </DndProvider>
                      </ApolloProvider>
                    </ModalProvider>
                  </TraitsProvider>
                </MoleculesProvider>
              </UserProvider>
            </WhitelistsProvider>
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
        <NotificationContainer />
      </Router>
    )
}

export default App
