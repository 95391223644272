import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useContractFunction } from "@usedapp/core";
import { utils, ethers } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import {NotificationManager} from 'react-notifications';

import Button from '../../Elements/Button.jsx';
import SectionContainerFlat from '../../Elements/SectionContainerFlat';
import Dashline from '../../Elements/Dashline.jsx';
import Text from '../../Elements/Text';
import CheckBox from '../../Elements/CheckBox.jsx';
import PointsInput from '../../Elements/PointsInput';
import LamexSidebar from '../LamexSidebar';
import TextContainer from '../../Elements/TextContainer';
import TermsAndConditions from './TermsAndConditions';

import {cards, cardLevels, creepzYield, armsYield, megaYield} from '../../Data/LamexData';
import {Config} from "../../../../config";
import {transaferLeaderboardPoints} from '../../../../plugins/lamexModel';
import {getLamexBalance, signLeaderboardPointsTransfer} from '../../../../plugins/web3';
import {getFutureLamexTier} from '../../../../plugins/utils';
import {lamexAbi} from '../../../../assets/blockchain/lamex';

import shard from '../../../../assets/season-2/common/season2Shard.svg'

import UserContext from '../../../../context/UserContext'

const provider = ethers.getDefaultProvider(Config.infuraUri);
const lamexInterface = new utils.Interface(lamexAbi);
const lamexContract = new Contract(Config.lamexAddress, lamexInterface, provider);

const Lamex = ({
    account,
    activateWallet,
    setCurrentState
  }) => {
    const user = useContext(UserContext);

    const { state: lamexState, send: claimLamex } = useContractFunction(lamexContract, 'claimLamex', { transactionName: 'claimLamex' });

    const [activeTab, setActiveTab] = useState(0);
    const [loomiTransaferAccepted, setLoomiTransaferAccepted] = useState(false);
    const [tncAccepted, setTncAccepted] = useState(false);
    const [lamexYieldAccepted, setLamexYieldAccepted] = useState(false);
    const [tabsDisabled, setTabsDisabled] = useState(true);

    const [tabs, setTabs] = useState([
      {
        text: `Begin Application`,
        active: true,
        id: 0
      },
      {
        text: `NFT Conversion`,
        active: false,
        id: 1,
        disabled: true
      },
      {
        text: `Open Account`,
        active: false,
        id: 2,
        disabled: true
      }
    ]);

    const applicationButtons = [
      {
        id: 0,
        title: `Begin Lamex Activation`,
        onClick: () => proceedWithApplication(false)
      },
      {
        id: 1,
        title: `Do This Later`,
        onClick: () => proceedWithApplication(true)
      },
      {
        id: 2,
        title: `Continue To Selection`,
        onClick: () => switchTab(2)
      },
      {
        id: 3,
        title: `Mint`,
        onClick: () => claimLamex(false)
      }
    ]

    const returnCurrentButtons = () => {
      if (activeTab === 0) {
        setCurrentButtons(applicationButtons.filter(x => x.id === 0 || x.id === 1));
        return
      }
      if (activeTab === 1) {
        setCurrentButtons(applicationButtons.filter(x => x.id  === 2));
        return
      } else {
        setCurrentButtons([])
      }
    }

    useEffect(() => {
      if (!tabsDisabled) {
        setTabs(prev => {
          return prev.map(x => {
            return {
              ...x,
              disabled: false
            }
          })
        })
      }
    }, [tabsDisabled]);
    
    const [currentButtons, setCurrentButtons] = useState([]);

    const switchTab = (newTab) => {
      setTabs(prev => prev.map(x => x.id === newTab ? {...x, active: true} : {...x, active: false}));
      setActiveTab(newTab);
    }

    const getGasEstimation = async (n) => {
      try {
        const estimation = await lamexContract.estimateGas.claimLamex(n, {from: account});
        return estimation.toString();
      } catch (e) {
        return null
      }
    }  

    const triggerClaimTransaction = async () => {
      const userBalance = await getLamexBalance(account);
      if (userBalance > 0) {
        NotificationManager.error('You already own a Lamex!');
        return;
      }
      const gas =  await getGasEstimation(+user.loomiBalance >= 5000 ? loomiTransaferAccepted : false);
      if (gas !== null) {
        const gasLimit = String(Math.round(gas * 1.5))
        console.log(gas, gasLimit);
        claimLamex(+user.loomiBalance >= 5000 ? loomiTransaferAccepted : false, {gasLimit: gasLimit});
        return;
      }

      claimLamex(+user.loomiBalance >= 5000 ? loomiTransaferAccepted : false)
    }

    const proceedWithApplication = async (isToSkip) => {
      if (!user.hasTransferedPoints) {
        const signature = await signLeaderboardPointsTransfer(account);
        const pointsTransfer = await transaferLeaderboardPoints(account, signature);
        if (pointsTransfer?.status !== 201) {
          NotificationManager.warning("Probably you already activated your account");
        } else {
          NotificationManager.success("Step 1 complete");
        }
      }
      if (isToSkip) {
        user.updateUserData(account);
        setCurrentState(`lamex`, true);
      } else {
        setTabsDisabled(false);
        switchTab(1);
      }
    }

    useEffect(() => returnCurrentButtons(), [activeTab])
    useEffect(() => {
      const status = lamexState.status;
      if (status === 'None') return;
      switch (status) {
        case 'Mining':
          NotificationManager.info('Lamex mint is pending!')
          break;
        case 'Success': 
          NotificationManager.success('Lamex has been claimed successfully!')
          user.updateUserData(account);
          user.setGraphData({
            ...user,
            isYieldingLamex: true,
          })
          user.setMainData({
            ...user,
            isLamexOwner: true
          })
          setCurrentState(`lamex`, true);
          break;
        case 'Exception': 
          if (lamexState.errorMessage.includes('User denied transaction')) {
            break;
          } else if (lamexState.errorMessage.includes('Contract paused!')) {
            NotificationManager.error('Lamex mint hasnt started yet!')
            break;
          } else if (lamexState.errorMessage.includes('You cannot mint more than 1 Lamex')) {
            NotificationManager.error('You already own a Lamex!')
            break;
          } else {
            NotificationManager.error('Something went wrong, try again!')
            break;
          }
      }
    }, [lamexState]);

    const triggerExtraTab = (toOpen) => {
      setExtraTab(prev => {
        return {
          ...prev,
          isOpened: toOpen,
        }
      })
    }
    const [extraTab, setExtraTab] = useState({
      header: `Terms & Conditions`,
      isOpened: false,
      onClick: () => triggerExtraTab(false)
    });

    const [tncRead, setTncRead] = useState(false);
    
    const acceptTnc = () => {
      setTncRead(true);
      triggerExtraTab(false);
      setTncAccepted(true);
    }

    return (
      <>        

        <LamexSidebar account={account} activateWallet={activateWallet} />
        <div className="dashboard-content">
          <div className="content-menu"><Link to="/season-2" onClick={() => setCurrentState(`start`)}><span>BACK TO DASHBOARD</span></Link></div>
          {/* <div className="content-menu"><img src={menuIcon} alt=""/></div> */}
          <div className="content-body">
          <SectionContainerFlat
            tabs={tabs}
            setTab={switchTab}
            bottomButtons={currentButtons}
            extraTab={extraTab}
            extraTabContent={<TermsAndConditions onClick={acceptTnc}/>}
          >
            {
              activeTab === 0 ? (
                <>
                    <Text textType='header' content={"Start Application process"}/>
                    <Dashline />
                    <div className="col-container">
                      <div className="col">
                        <Text content={"To begin activate your LAMEX"}/>
                        <TextContainer title={`LAMEX Balance`}>
                          <Text content={"• By activating LAMEX you will switch your yield to LAMEX points."}/><br />
                          <Text content={"• LAMEX points cannot be exchanged for $LOOMI and are an in-game point."}/><br />
                          <Text content={"• You can switch back to yielding $LOOMI at any point you wish by leaving LAMEX."}/><br />
                          <Text content={"• By activating LAMEX you will switch your yield to LAMEX points."}/><br />
                          <Text content={"• Your LAMEX tier is automatically calculated on your Genesis Creepz staked. If you have 0 genesis you will get the duck tier."}/><br />
                        </TextContainer>
                      </div>
                      <div className="col">
                        <Text content={"LOOMI to LAMEX exchange rate (may change)"}/>
                        <TextContainer title={`LOOMI`} color={`#8DFF1F`}>
                          <div className="lamex__balance-container">
                            <div className="lamex-balance" style={{color: `#8DFF1F`}}>5000<span> $loomi</span></div>
                          </div>
                        </TextContainer>
                        <div style={{width: `100%`, textAlign: `center`, color: "#64F7F7", fontSize: `18px`}} className="mb-20"> ≈ </div>
                        <TextContainer title={`LAMEX`}>
                          <div className="lamex__balance-container">
                            <div className="lamex-balance">1<span> pts</span></div>
                          </div>
                        </TextContainer>
                      </div>
                    </div>
                    
                </> 
              ) : activeTab === 1 ? (
                  <>
                    <Text textType='header' content={"LAMEX yield breakdown"}/>
                    <Dashline />
                    <div className="col-container">
                      <div className="col">
                        <Text content={"Summary"}/>
                        <TextContainer title={`Overview`}>
                          <Text content={"Your staked NFT’s will instantly begin yielding LAMEX points for you to spend in a gas free way. This is lizard innovation."}/>
                        </TextContainer>
                        <PointsInput
                          title="Estimated LAMEX Yield"
                          value={(+user.lamexYield).toFixed(2)}
                          inputTitle="PTS"
                          width='320px'
                        />
                      </div>
                      <div className="col">
                        <Text content={"LAMEX points yield per day"}/>
                        <div className="col-container">
                          <div className="col">
                            <TextContainer title={`Genesis Creepz`}>
                              {
                                creepzYield.map(x => {
                                  return (
                                    <div className="yield-container">
                                      <div>{x.color}</div>
                                      <div>{x.yield}</div>
                                    </div>
                                  )
                                })
                              }
                            </TextContainer>
                          </div>
                          <div className="col">
                            <TextContainer title={`Reptile Armoury`}>
                              {
                                armsYield.map(x => {
                                  return (
                                    <div className="yield-container">
                                      <div>{x.color}</div>
                                      <div>{x.yield}</div>
                                    </div>
                                  )
                                })
                              }
                            </TextContainer>
                          </div>
                        </div>
                        <div className="col-container">
                          <div className="col">
                            <TextContainer title={`MEGAS (SHARDS)`}>
                              {
                                megaYield.map(x => {
                                  return (
                                    <div className="yield-container">
                                      <div>{x.color}</div>
                                      <div><img src={shard} alt="Shard img" width="10px"/> {x.yield}</div>
                                    </div>
                                  )
                                })
                              }
                            </TextContainer>
                          </div>
                          <div className="col">
                            <TextContainer title={`Vaults`}>
                              <div className="yield-container">
                                <div>Vault</div>
                                <div>0.5</div>
                              </div>
                            </TextContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Text textType='header' content={"Sign with your lizard blood & mint LAMEX card"}/>
                    <Dashline />
                    <div className="col-container">
                      <div className="col">
                        <Text content={"Tiers are based on Genesis Creepz staked"}/>
                        <div className="future-card-container">
                          <img src={cards[getFutureLamexTier(user.stakedCreepz.length)]} alt="Lamex Card" />
                          <div className="future-card-text">You’ve been approved for {cardLevels[getFutureLamexTier(user.stakedCreepz.length)]}</div>
                        </div>
                      </div>
                      <div className="col">
                        <Text content={"Select your preferences below"}/>
                        <CheckBox text={`Start yielding LAMEX`} isSelected={lamexYieldAccepted} onClick={() => setLamexYieldAccepted(prev => !prev)}/>
                        <CheckBox text={`Accept Terms & Conditions`} isSelected={tncAccepted} onClick={!tncRead ? () => {triggerExtraTab(true)} : () => setTncAccepted(prev => !prev)}/>
                        <CheckBox text={`Convert all your $loomi (optional)`} secondText={`5000 LOOMI = 1 LAMEX`} isSelected={loomiTransaferAccepted} onClick={() => setLoomiTransaferAccepted(prev => !prev)}/>
                        <Button 
                          buttonText={lamexYieldAccepted && tncAccepted ? `Accept & Mint LAMEX` : `Preferences not complete`} 
                          background={lamexYieldAccepted && tncAccepted ? `#64F7F7` : `#755D9D`}
                          color={lamexYieldAccepted && tncAccepted ? `#101820` : `rgba(255, 255, 255, 0.3)`}
                          onClick={lamexYieldAccepted && tncAccepted ? () => triggerClaimTransaction() : () => {}}
                        />
                      </div>
                    </div>
                  </>
                )
            }
          </SectionContainerFlat>
        </div>
      </div>
    </>
  )
}

export default Lamex;
