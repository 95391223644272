import React, { useContext, useState } from 'react';
import { getPacks } from '../plugins/traitsModel';
import { packsImages, packslotties } from '../components/Dashboard/Data/packsData';
import {getSkinByLevel, getBgByLevel} from '../components/Dashboard/Data/idsData';
import {getUserIdLevel} from '../plugins/utils'
import {getUserTraits} from '../plugins/dnaModel';

const TraitsContext = React.createContext(null);
TraitsContext.displayName = 'TraitsContext';

const TraitsProvider = ({ children }) => {
  const [packs, setPacks] = useState([]);
  const [traits, setTraits] = useState([]);
  const [backgrounds, setBackgrounds] = useState([]);

  return (
    <TraitsContext.Provider
      value={{
        packs,
        traits,
        backgrounds,
        getPacksData: async () => {
          const packs = await getPacks();
          let newData = packs.data.map(x => {
            return {
              ...x,
              image: packsImages[x.id - 1],
              lottie: packslotties[x.id - 1]
            }
          })

          let sorted = newData.sort((a,b) => Number(a.id) - Number(b.id))
          setPacks(sorted)
          return sorted;
        },
        getTraitsData: async (account, dnaScore) => {
          console.log("WE ARE HERE")
          const userTraits = await getUserTraits(account);
          if (userTraits && userTraits?.data.length) {
            let counter = 0;
            const transformedData = userTraits.data.map(x => {
              return {
                ...x,
                traits: x.traits.map(trait => {
                  return {
                    ...trait,
                    id: trait._id,
                    isSelected: false,
                    dndIdentifier: counter++
                  }
                }),
                isOpened: false,
                isBuilding: false
              }
            })

            const allTraits = [
              {
                category: `skins`,
                isOpened: true,
                traits: getSkinByLevel(getUserIdLevel(dnaScore))
              },
              ...transformedData
            ]

            setTraits(allTraits)

            return allTraits;
          }

          const allTraits = [
            {
              category: `skins`,
              isOpened: true,
              traits: getSkinByLevel(getUserIdLevel(dnaScore))
            }
          ]

          setTraits(allTraits)

          return allTraits;
        },
        getBackgrounds: (dnaScore) => {
          setBackgrounds(getBgByLevel(getUserIdLevel(dnaScore)))
        }
      }}
    >
      {children}
    </TraitsContext.Provider>
  );
};

export { TraitsProvider };
export default TraitsContext;