import axios from 'axios';
import * as Sentry from "@sentry/react";
import { Config } from '../config';

const getRequest = async (url) => {
  try {
    const response = await axios.get(`${Config.apiLink}/${url}`)
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return {
        data: []
      };
  }
}

const postRequest = async (url, args) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/${url}`,
      method: 'post',
      data: {
        ...args
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const getUserIDs = async (address) => {
  return await getRequest(`users/${address}/interdimensionals`);
}

export const evolveId = async (address, name, traitsMap, signature) => {
  return await postRequest(
    `users/${address}/create-interdimensional`,
    {
      name,
      traitsMap,
      signature
    }
  )
}

export const validateIdName = async (name) => {
  return await postRequest(
    `validate-id-name`,
    {
      name,
    }
  )
}
