import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { DAppProvider } from "@usedapp/core";
import {Config} from "./config";
import {PopupProvider} from "./context/PopupContext"
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: "https://57647fc236214bc5a20a4e30eed52a04@o1086828.ingest.sentry.io/6099341",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_RUNTIME_ENV || "production",
    tracesSampleRate: 1.0,
    enabled: process.env.NODE_ENV !== "development"
});


const config = {
    readOnlyChainId: Config.targetChain,
    readOnlyUrls: {
      [Config.targetChain]: Config.infuraUri,
    },
};

ReactDOM.render(
  <DAppProvider config={config}>
    <PopupProvider>
        <App />
    </PopupProvider>
  </DAppProvider>,
  document.getElementById('root')
)
