import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti'

const ModalContext = React.createContext(null);
ModalContext.displayName = 'ModalContext';

const ModalProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);

  const openModal = () => { 
    setIsActive(true);
  }

  const closeModal = () => { 
    setIsActive(false);
    setIsConfettiActive(false);
  }

  const closeModalViaKey = (e) => { 
    const key = e?.key;
    if (key === undefined || key === 'Escape') {
      setIsActive(false);
      setIsConfettiActive(false);
    }
   }

  const closeModalOnClick = (e) => { 
  const element = e?.target.className;
    if (element === "dashboard-popup active") {
      setIsActive(false);
      setIsConfettiActive(false);
    }
  }

  useEffect(() => {
    if (isActive) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = 'visible'
    }
  }, [isActive]);

  return (
    <ModalContext.Provider
      value={{
        isActive,
        setIsConfettiActive,
        setModalContent,
        openModal,
        closeModal
      }}
    >
      {
        isActive ? (
        <div 
          className={`dashboard-popup ${isActive ? `active` : ``}`}
          onKeyDown={(e) => closeModalViaKey(e)}
          onClick={(e) => closeModalOnClick(e)}
          tabIndex="0"
        >
          <Confetti run={isConfettiActive} recycle={false}/>
          {modalContent}
        </div>
        ) : ``
      }
      {children}
    </ModalContext.Provider>
  );
};

export { ModalProvider };
export default ModalContext;