import { useState, useContext, useEffect, useRef } from 'react';
import Lottie from "lottie-react";
import { utils, ethers } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import {NotificationManager} from 'react-notifications';

import Button from '../Elements/Button.jsx';

import {Config} from "../../../config";
import {lamexAbi} from '../../../assets/blockchain/lamex';

import portalLottie from '../../../assets/season-2/portal/PortalLottie.json';
import shard1 from '../../../assets/season-2/portal/Shard1.png';
import shard2 from '../../../assets/season-2/portal/Shard2.png';
import shard3 from '../../../assets/season-2/portal/Shard3.png';

import UserContext from '../../../context/UserContext'
import SectionHeader from '../Elements/SectionHeader';

import {signPortalOpening, signDnaBoost, signMoleculeUsage} from '../../../plugins/web3'

import {openPortal} from '../../../plugins/dnaModel'

const provider = ethers.getDefaultProvider(Config.infuraUri);
const lamexInterface = new utils.Interface(lamexAbi);
const lamexContract = new Contract(Config.lamexAddress, lamexInterface, provider);

const PortalOpening = ({
    account,
    setCurrentState,
    pause,
    start
  }) => {
    const user = useContext(UserContext);

    const sectionColor = `#F764C6`;
    
    const [openButtonHidden, setOpenButtonHidden] = useState(true);
    const [portalOpened, setPortalOpened] = useState(false);
    const [portalOpenfailed, setPortalOpenfailed] = useState(false);
    const [currentSegmentPlayed, setCurrentSegmentPlayed] = useState([0, 200]);
    const [buttonText, setbuttonText] = useState(`Open the portal`);
    const [portalAnimation, setPortalAnimation] = useState(`none`);
    
    const animation = `zoomPortal 3s linear`;
    
    useEffect(() => {
      pause();
      setTimeout(() => setOpenButtonHidden(prev => !prev), 3000);
    }, []);

    const triggerPortalOpening = async () => {
      const signature = await signPortalOpening(account);
      const portalOpenResponse = await openPortal(account, signature);
      if (portalOpenResponse?.status === 200 || (portalOpenResponse?.message.includes(`DNA already exists`))) {
        start();
        setCurrentSegmentPlayed([200, 500]);
        setbuttonText(`ENTER NEW DIMENSION`);
        setPortalOpened(true);
        setTimeout(() => setCurrentSegmentPlayed([240, 500]), 3000);
      } else {
        NotificationManager.error(`You need shards to open the portal!`);
        setPortalOpenfailed(true);
        setbuttonText(`Go back to dashboard`);
      }
    }
    
    const openDashboard = async () => {
      setPortalAnimation(animation)
      setOpenButtonHidden(true);
      setTimeout(() => setCurrentState(`idDashboard`), 3000);
    }

    return (
    <>
      <div className="portal-container">
        {openButtonHidden ? `` : <SectionHeader header={`The Portal`} width={`320px`} color={sectionColor}/> }
        <div className="portal-overlay">
          <div className="portal">
            {/* <img src={portal} alt="Portal Image"/> */}
            <div className="blur"></div>
            <div style={{maxWidth: `700px`, marginTop: `-70px`, animation: portalAnimation}}>
              <Lottie animationData={portalLottie} style={{
              }} initialSegment={currentSegmentPlayed} />
            </div>
            {/* 0-200, 200-240, 240 */}
          </div>
          {
            portalOpened ? `` : (
              <>
                <div className="shard shard1"><img src={shard2} alt="Shard 1"/></div>
                <div className="shard shard2"><img src={shard3} alt="Shard 2" /></div>
                <div className="shard shard3"><img src={shard1} alt="Shard 3" /></div>
              </>
            )
          }
          { openButtonHidden ? `` : 
            <Button 
              buttonText={buttonText} 
              color={sectionColor} 
              width={`320px`} 
              extraClass="portal-button" 
              onClick={portalOpenfailed ? () => {setCurrentState(`start`)} : !portalOpened ? triggerPortalOpening : openDashboard}
            /> 
            }
        </div>
        
      </div>        
    </>
  )
}

export default PortalOpening;
