import { useContext, useState, useEffect } from 'react';
import { useContractFunction } from "@usedapp/core";
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import {NotificationManager} from 'react-notifications';
import Web3 from 'web3';

import {Config} from "../../../config";

import PointsInput from '../Elements/PointsInput';
import Button from '../Elements/Button.jsx';
import Input from '../Elements/Input';

import UserContext from '../../../context/UserContext';

import {lamexAbi} from '../../../assets/blockchain/lamex';

const lamexInterface = new utils.Interface(lamexAbi);
const lamexContract = new Contract(Config.lamexAddress, lamexInterface);
const web3 = new Web3(window.ethereum || Config.infuraUri);

const TopUpLamexComponent = ({setExtraTab}) => {
  const minDepositAmount = 5000;

  const user = useContext(UserContext);
  const { state: lamexState, send: topUp } = useContractFunction(lamexContract, 'topUpLamex', { transactionName: 'topUpLamex' });

  const [topUpInput, setTopUpInput] = useState(0)
  const [isInputValid, setIsInputValid] = useState(false)

  const changeValue = (e) => {
    const value = e.target.value;
    if (+value > +user.loomiBalance) return String(user.loomiBalance);

    if (+value < minDepositAmount) {
      setIsInputValid(false);
    } else {
      setIsInputValid(true);
    }

    setTopUpInput(value)
  }
  
  const setMax = () => {
    if (+user.loomiBalance < minDepositAmount) {
      setIsInputValid(false);
    } else {
      setIsInputValid(true);
    }

    setTopUpInput(String(user.loomiBalance))
  }

  const topUpLamex = () => {
    if (topUpInput < minDepositAmount) {
      NotificationManager.error(`Min deposit is ${minDepositAmount} $loomi!`);
      return;
    };
    topUp(web3.utils.toWei(topUpInput));
  }

  useEffect(() => {
    const status = lamexState.status;
    if (status === 'None') return;
    switch (status) {
      case 'Mining':
        NotificationManager.info("Processing your LAMEX deposit!")
        break;
      case 'Success': 
        NotificationManager.success('Deposited successfully! Your LAMEX balance will be updated within 5 minutes.');
        setExtraTab(false);
        break;
      case 'Exception': 
        if (lamexState.errorMessage.includes('User denied transaction')) {
          break;
        } else {
          NotificationManager.error('Deposit failed, please try again!');
          break;
        }
    }
  }, [lamexState])

  return (
    <>
      <div className="topUp-container">
        <div className="col-container">
          <PointsInput
            title="LAMEX Balance"
            value={user.lamexBalance}
            inputTitle={`PTS`}
          />
          <PointsInput
            title="$loomi Balance"
            value={user.loomiBalance}
            inputTitle={`PTS`}
          />
        </div>

        <div className="lamex-amount_input_container">
          <div className="input_title">Enter $loomi amount to deposit</div>
          <Input 
            value={topUpInput}
            onChange={(e) => changeValue(e)}
            onClick={() => setMax()}
          />
          <div className="input_title">NOTE! Deposit might take up to 5 minutes to show up in your account</div>
        </div>

        <div className="col-container">
          <PointsInput
            title="LAMEX to receive"
            value={(+topUpInput / minDepositAmount).toFixed(2)}
            inputTitle={`PTS`}
          />
          <PointsInput
            title="New LAMEX Balance"
            value={(+user.lamexBalance + topUpInput / minDepositAmount).toFixed(2)}
            inputTitle={`PTS`}
            extraClass="mb-20"
          />
        </div>
        <Button
          buttonText={`Confirm`}
          onClick={isInputValid ? topUpLamex : () => {}}
          background={isInputValid ? `#64F7F7` : `#755D9D`}
          color={isInputValid ? `#101820` : `rgba(255, 255, 255, 0.3)`}
        />
      </div>
    </>
  )
}
export default TopUpLamexComponent;