import React, { useEffect, useState, Suspense, useContext } from "react";

import { useEthers } from "@usedapp/core";
import "../../scss/styles.scss";
import Intro from "../sections/Intro";
import City from "../sections/City/City";
import News from "../generic/News";
import StoneInterlayer from "../generic/StoneInterlayer";
import BlocksInterlayerGreen from "../generic/BlocksInterlayerGreen";
import ScrollHandler from "../generic/ScrollHandler";
import Loader from "../generic/Loader";
import ModalLarge from "../sections/ModalLarge";
import { Config } from "../../config";
import overlordIcon from "../../assets/intro/overlord-logo.svg";

import PopupContext from "../../context/PopupContext";

import MB from "../../assets/mystery/MysteryBox.gif";

const Statistic = React.lazy(() => import("../sections/statistic"));
const Popups = React.lazy(() => import("../sections/Popups"));
const Pyramid = React.lazy(() => import("../sections/Pyramid"));
const Plan = React.lazy(() => import("../sections/Plan"));
const Discord = React.lazy(() => import("../sections/Discord"));
const Armoury = React.lazy(() => import("../sections/Armoury"));
const Creators = React.lazy(() => import("../sections/creators"));

const Footer = React.lazy(() => import("../sections/Footer"));

function Main() {
  const alert = useContext(PopupContext);

  const [activePopup, setActivePopup] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [activeStaking, setActiveStaking] = useState(false);
  const [activeMintModal, setActiveMintModal] = useState(false);
  const [activeBank, setActiveBank] = useState(false);
  const [activeMutatation, setActiveMutation] = useState(false);
  const [activeSelectClaimModal, setActiveSelectClaimModal] = useState(false);
  const [activeVaultStaking, setActiveVaultStaking] = useState(false);
  const [activeTample, setActiveTample] = useState(false);
  const [activeSacrifice, setSacrificeActive] = useState(false);
  const [activeNameChange, setActiveNameChange] = useState(false);
  const [activeSpinResult, setActiveSpinResult] = useState(false);
  const [activeCommonModal, setActiveCommonModal] = useState(false);
  const [aboutModal, setAboutModal] = useState(false);

  const [totalSold, setTotalSold] = useState(11111);
  const [shapeSold, setShapeSold] = useState(20000);
  const [armsSold, setArmsSold] = useState(20000);

  const [sholdDiscordType, setShouldDiscordType] = useState(false);
  const [sholdArmoryType, setShouldDArmoryType] = useState(false);
  const [scrollForPlan, setScrollForPlan] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [activeCollection, setActiveCollection] = useState(2);
  const [creepzSaleData, setCreepzSaleData] = useState({});
  const [mysteryBox, setMysteryBox] = useState({});
  const [shapeSaleData, setShapeSaleData] = useState({});

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { chainId, account } = useEthers();

  const getSaleData = async () => {
    setMysteryBox({
      saleActive: true,
      name: `Mystery Box`,
      remaining: ``,
      maxTokens: ``,
      imageType: `img`,
      image: MB,
      collectionId: 1,
      price: ``,
      limit: ``,
      whitelist: false,
      max: 50,
      isApproved: true,
    });
  };

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000);
    getSaleData();
  }, []);

  useEffect(() => {
    if (account && window.location.hash.includes(`creepz-staking`)) {
      setActiveStaking(true);
    }
    if (account && window.location.hash.includes(`vault-staking`)) {
      setActiveVaultStaking(true);
    }
  }, [account]);

  useEffect(() => {
    if (chainId !== undefined && chainId !== Number(Config.targetChain)) {
      alert.showNetworkError();
    }
  }, [chainId]);

  useEffect(() => {
    if (
      activePopup ||
      activeModal ||
      activeStaking ||
      activeSelectClaimModal ||
      activeVaultStaking
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [
    activePopup,
    activeModal,
    activeStaking,
    activeSelectClaimModal,
    activeTample,
  ]);

  const openStakingModal = () => {
    setActiveStaking(true);
  };

  const resetPopup = () => {
    alert.clear();
  };

  const closeMintModal = () => {
    setActiveMintModal(false);
  };

  const openMintModal = (collectionToMint) => {
    setActiveCollection(collectionToMint);
    setActiveMintModal(true);
  };

  return (
    <>
      <Suspense fallback={<div></div>}>
        <Popups
          resetPopup={resetPopup}
          activeMintModal={activeMintModal}
          setActiveMintModal={setActiveMintModal}
          closeMintModal={closeMintModal}
          setActivePopup={setActivePopup}
          creepzSaleData={creepzSaleData}
          mysteryBox={mysteryBox}
          shapeSaleData={shapeSaleData}
          activeCollection={activeCollection}
          activeBank={activeBank}
          setActiveBank={setActiveBank}
          activeStaking={activeStaking}
          setActiveStaking={setActiveStaking}
          activeSelectClaimModal={activeSelectClaimModal}
          setActiveSelectClaimModal={setActiveSelectClaimModal}
          account={account}
          setActiveCollection={setActiveCollection}
          activeMutatation={activeMutatation}
          setActiveMutation={setActiveMutation}
          activeVaultStaking={activeVaultStaking}
          setActiveVaultStaking={setActiveVaultStaking}
          activeTample={activeTample}
          setActiveTample={setActiveTample}
          activeSacrifice={activeSacrifice}
          setSacrificeActive={setSacrificeActive}
          activeSpinResult={activeSpinResult}
          setActiveSpinResult={setActiveSpinResult}
          activeNameChange={activeNameChange}
          setActiveNameChange={setActiveNameChange}
          activeCommonModal={activeCommonModal}
          setActiveCommonModal={setActiveCommonModal}
          setAboutModal={setAboutModal}
          aboutModal={aboutModal}
        />
      </Suspense>

      {activeModal ? (
        <Suspense fallback={<div></div>}>
          <ModalLarge setActiveModal={setActiveModal} />
        </Suspense>
      ) : (
        ""
      )}

      {isLoading ? <Loader /> : ""}
      <ScrollHandler
        setShouldDiscordType={setShouldDiscordType}
        setShouldDArmoryType={setShouldDArmoryType}
        setScrollForPlan={setScrollForPlan}
      />
      <Intro
        sold={totalSold}
        openPopup={setActivePopup}
        openMintModal={openMintModal}
        setActiveModal={setActiveModal}
        creepzSaleData={creepzSaleData}
        setActiveBank={setActiveBank}
        setActiveSelectClaimModal={setActiveSelectClaimModal}
        openStakingModal={openStakingModal}
        setActiveMutation={setActiveMutation}
        setActiveVaultStaking={setActiveVaultStaking}
        setActiveTample={setActiveTample}
        setActiveMintModal={setActiveMintModal}
        setActiveNameChange={setActiveNameChange}
        setAboutModal={setAboutModal}
      />

      <News
        colorBorder="#101820"
        text={["THE END OF THE WORLD -"]}
        colorBackground="#FA4B28"
        colorText="#FFFFFF"
        link={false}
        point={false}
      />
      <a href="https://overlord.xyz">
        <div className="overlord-link">
          <img src={overlordIcon} alt="Overlord Icon" width={`100%`} />
        </div>
      </a>
      {/* <City 
              openPopup={setActivePopup}
              totalSold={totalSold}
              shapeSold={shapeSold}
              armsSold={armsSold}
              openMintModal={openMintModal}
              setActiveSelectClaimModal={setActiveSelectClaimModal}
            />
            <News
                colorBorder='#fff'
                text={['multi season ecosystem', '🔥']}
                colorBackground='#2194FF'
                colorText='#FFFFFF'
                link={false}
                point={true}
            /> */}

      {/* <StoneInterlayer/>

            <Suspense fallback={<div></div>}>
                <Armoury sholdArmoryType={sholdArmoryType}/>
            </Suspense>

            <BlocksInterlayerGreen color='green'/>

            <Suspense fallback={<div></div>}>
              <Statistic 
                setActiveStaking={setActiveStaking} 
                armsSold={armsSold}
                shapeSold={shapeSold}
              />
            </Suspense>

            <BlocksInterlayerGreen color='green'/>
            <Suspense fallback={<div></div>}>
                <Plan shouldPlay={scrollForPlan}/>
            </Suspense>
            <BlocksInterlayerGreen color='blue'/>
            <BlocksInterlayerGreen color='blue'/>

            <Suspense fallback={<div></div>}>
                <Pyramid setActivePopup={setActivePopup} openMintModal={openMintModal}/>
            </Suspense>

            <BlocksInterlayerGreen color='blue'/>
            <Suspense fallback={<div></div>}>
                <Discord shouldType={sholdDiscordType}/>
            </Suspense>

            <BlocksInterlayerGreen color='blue'/>

            <Suspense fallback={<div></div>}>
                <Creators/>
            </Suspense>
            <BlocksInterlayerGreen color='green'/> */}

      <Suspense fallback={<div></div>}>
        <Footer
          setActivePopup={setActivePopup}
          openMintModal={openMintModal}
          setActiveCommonModal={setActiveCommonModal}
        />
      </Suspense>
    </>
  );
}

export default Main;
