import { useEffect, useRef, useState, useContext } from "react";
import { Link } from "react-scroll";
import { Link as Routerlink } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import Lottie from "lottie-react";
import { Config } from "../../config";
import PopupContext from "../../context/PopupContext";
import { useHistory } from "react-router-dom";

import logo from "../../assets/intro/creepz-logo.png";
import twitter from "../../assets/intro/twitter.svg";
import insta from "../../assets/intro/insta.svg";
import discord from "../../assets/intro/discord.svg";
import os from "../../assets/footer/union.svg";
import globe from "../../assets/intro/land.png";
import creep from "../../assets/intro/creep-overlord.svg";
import logoScroll from "../../assets/intro/scroll-logo.svg";
import logoMenu from "../../assets/intro/menu-logo.png";
import starmap from "../../assets/intro/stars.png";
import stonesR from "../../assets/intro/stones-r.svg";
import itemsR from "../../assets/intro/itemsR.svg";
import eyeL from "../../assets/intro/eyeL.svg";
import itemsL from "../../assets/intro/itemsL.svg";
import itemsBottomR from "../../assets/intro/itemsBottomR.svg";
import nikes from "../../assets/intro/nikes.svg";
import duck from "../../assets/intro/duck.svg";

import eye from "../../assets/intro/eye.json";
import hand from "../../assets/intro/hands.json";

import Button from "../generic/Button";

const durationAnimationScroll = 1000;

const Intro = ({
  setActiveTample,
  setActiveMintModal,
  setActiveNameChange,
  openPopup,
  setPopupStep,
  setActiveVaultStaking,
  setActiveModal,
  setActiveMutation,
  openStakingModal,
  setActiveBank,
  openMintModal,
  setAboutModal,
}) => {
  const alert = useContext(PopupContext);
  const { activateBrowserWallet, account } = useEthers();
  const history = useHistory();

  const refHeader = useRef();
  const [isActiveHeader, setIsActiveHeader] = useState(false);
  const [isActiveMenu, setIsActiveMenu] = useState(false);

  const openHowToPlay = () => {
    setActiveModal(true);
  };

  const navs = [
    {
      text: "Capsule One",
      section: "intro",
      type: "internal",
      action: () => window.open("https://na7i8wc1iql.typeform.com/to/aMdIz5k6"), //change the route to real one
      disabled: false,
    },
    // {
    //   text: "About",
    //   section: "",
    //   type: "internal",
    //   action: () => setAboutModal(true),
    //   disabled: false,
    // },
    // {
    //     text: 'Bank',
    //     section: '',
    //     type: 'internal',
    //     action: () => {!account ? activateWallet() : setActiveBank(true)},
    //     disabled: false
    // },
    // {
    //   text: "Season 2",
    //   section: "/season-2",
    //   type: "internal",
    //   action: () => {},
    //   disabled: true,
    // },
  ];

  const onError = () => {
    alert.showMetamskError();
  };
  const activateWallet = async () => {
    if (!account) {
      activateBrowserWallet(onError);
    }
  };

  const openWpWindow = () => {
    setPopupStep(3);
    openPopup(true);
  };

  const openModalWindow = () => {
    setPopupStep(Config.isSaleActive ? 0 : 1);
    openPopup(true);
  };

  const changeStateMenu = () => {
    setIsActiveMenu((isActiveMenu) => !isActiveMenu);

    if (!isActiveMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  };

  useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY > refHeader.current.scrollHeight) {
        setIsActiveHeader(true);
      } else {
        setIsActiveHeader(false);
      }
    };

    document.addEventListener("scroll", scrollListener);
    return () => {
      document.removeEventListener("scroll", scrollListener);
    };
  }, []);

  return (
    <header
      ref={refHeader}
      className={`intro ${isActiveMenu ? "active-menu" : ""}`}
    >
      <div className="container container_header">
        <div className={`header-scroll ${!isActiveHeader ? "active" : ""}`}>
          <img
            className="header-scroll__logo"
            src={logoScroll}
            alt="logoScroll"
          />
          <nav className="header-scroll__navs">
            {navs.map(
              ({ text, section, type, disabled, action }, index) =>
                type === "internal" ? (
                  <Link
                    onClick={disabled ? null : action}
                    key={text + index}
                    className={`header-scroll_nav ${
                      disabled ? "disabled" : ""
                    }`}
                    to={disabled ? null : section}
                    // duration={durationAnimationScroll}
                    smooth={true}
                    offset={-50}
                  >
                    <span>{text}</span>
                  </Link>
                ) : type === "external" ? (
                  <a className="header-scroll_nav" href={section}>
                    <span>{text}</span>
                  </a>
                ) : (
                  <Routerlink
                    className={`header-scroll_nav router ${
                      disabled ? "disabled" : ""
                    }`}
                    to={section}
                  >
                    {text}
                  </Routerlink>
                )
              // <div className="header-scroll_nav" ><span onClick={openWpWindow}>{text}</span></div>
            )}
          </nav>

          <div className="buttonContainer">
            <div className="header__buttons">
              <div className="header__button">
                <div>
                  <a
                    href="https://twitter.com/overlord_xyz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt="twitter" />
                  </a>
                </div>
              </div>
              <div className="header__button">
                <div>
                  <a
                    href="https://discord.gg/overlordxyz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={discord} alt="discord" />
                  </a>
                </div>
              </div>
              <div className="header__button">
                <div>
                  <a
                    href="https://www.instagram.com/creepz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={insta} alt="instagram" />
                  </a>
                </div>
              </div>
              <div className="header__button">
                <div>
                  <a
                    href="https://opensea.io/collection/genesis-creepz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={os} alt="opensea" />
                  </a>
                </div>
              </div>
            </div>

            {/* <Button
              extraClass="header-scroll__button"
              text={
                !account
                  ? `Connect Wallet`
                  : `${account.slice(0, 6)}...${account.slice(
                      account.length - 6,
                      account.length
                    )}`
              }
              onClick={!account ? activateWallet : ``}
            /> */}
          </div>
        </div>

        <div className="header">
          <div className="header-scroll" style={{ position: `relative` }}>
            <div className="header__logo">
              <img src={logo} alt="logo" />
            </div>

            <nav className="header-scroll__navs">
              {navs.map(
                ({ text, section, type, disabled, action }, index) =>
                  type === "internal" ? (
                    <Link
                      onClick={disabled ? null : action}
                      key={text + index}
                      className={`header-scroll_nav ${
                        disabled ? "disabled" : ""
                      }`}
                      to={disabled ? null : section}
                      duration={durationAnimationScroll}
                      smooth={true}
                      disabled={disabled}
                      offset={-50}
                    >
                      <span>{text}</span>
                    </Link>
                  ) : type === "external" ? (
                    <a className="header-scroll_nav" href={section}>
                      <span>{text}</span>
                    </a>
                  ) : (
                    <Routerlink
                      className={`header-scroll_nav router ${
                        disabled ? "disabled" : ""
                      }`}
                      to={section}
                    >
                      {text}
                    </Routerlink>
                  )
                // <div className="header-scroll_nav" ><span onClick={openWpWindow}>{text}</span></div>
              )}
            </nav>

            <div className="buttonContainer">
              <div className="header__buttons">
                <div className="header__button">
                  <div>
                    <a
                      href="https://twitter.com/overlord_xyz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitter} alt="twitter" />
                    </a>
                  </div>
                </div>
                <div className="header__button">
                  <div>
                    <a
                      href="https://discord.gg/overlordxyz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={discord} alt="discord" />
                    </a>
                  </div>
                </div>
                <div className="header__button">
                  <div>
                    <a
                      href="https://www.instagram.com/creepz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={insta} alt="instagram" />
                    </a>
                  </div>
                </div>
                <div className="header__button">
                  <div>
                    <a
                      href="https://opensea.io/collection/genesis-creepz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={os} alt="opensea" />
                    </a>
                  </div>
                </div>
              </div>

              {/* <Button
                extraClass="header-scroll__button"
                text={
                  !account
                    ? `Connect Wallet`
                    : `${account.slice(0, 6)}...${account.slice(
                        account.length - 6,
                        account.length
                      )}`
                }
                onClick={!account ? activateWallet : ``}
              /> */}
            </div>
          </div>

          <div className="header__nav-mobile">
            <div className="header__logo">
              <img src={logo} alt="logo" />
            </div>
            <button
              onClick={() => changeStateMenu()}
              className="header__nav-mobile-button"
            >
              <div className="inner">
                <div />
                <div />
                <div />
              </div>
            </button>
          </div>

          <div
            className={`header__menu-mobile ${isActiveMenu ? "active" : ""}`}
          >
            <div className="header__nav-mobile ">
              <button
                onClick={() => changeStateMenu()}
                className="header__nav-mobile-button close"
              >
                <div className="inner">
                  <div />
                  <div />
                  <div />
                </div>
              </button>
            </div>

            <img className="logo" src={logoMenu} alt="logoMenu" />

            <nav className="header__menu-navs">
              {navs.map(
                ({ text, section, type, disabled, action }, index) =>
                  type === "internal" ? (
                    <Link
                      onClick={disabled ? null : action}
                      key={text + index}
                      className={`header__menu-nav ${
                        disabled ? "disabled" : ""
                      }`}
                      to={disabled ? null : section}
                      duration={durationAnimationScroll}
                      smooth={true}
                      disabled={disabled}
                      offset={-50}
                    >
                      <span>{text}</span>
                    </Link>
                  ) : type === "external" ? (
                    <a className="header__menu-nav" href={section}>
                      <span>{text}</span>
                    </a>
                  ) : (
                    <Routerlink
                      className={`header__menu-nav router ${
                        disabled ? "disabled" : ""
                      }`}
                      to={section}
                    >
                      {text}
                    </Routerlink>
                  )
                //<div className="header__menu-nav" ><span onClick={openWpWindow}>{text}</span></div>
              )}
            </nav>
            <Button
              extraClass="header-scroll__button"
              text={
                !account
                  ? `Connect Wallet`
                  : `${account.slice(0, 6)}...${account.slice(
                      account.length - 6,
                      account.length
                    )}`
              }
              onClick={!account ? activateWallet : ``}
              style={{ margin: `30px auto 0` }}
            />

            <div className="links">
              <div>
                <a
                  href="https://twitter.com/overlord_xyz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="twitter" />
                </a>
              </div>
              <div>
                <a
                  href="https://discord.gg/overlordxyz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={discord} alt="discord" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/creepz/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={insta} alt="insta" width="33px" />
                </a>
              </div>
              <div>
                <a
                  href="https://opensea.io/collection/genesis-creepz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={os} alt="opensea" />
                </a>
              </div>
            </div>

            <div className="pipe"></div>
          </div>
        </div>
        <div className="header__main">
          <img className="floating-item item-0" src={starmap} alt="" />
          <img className="floating-item item-1 desktop" src={stonesR} alt="" />
          <img className="floating-item item-2 desktop" src={itemsR} alt="" />
          <img
            className="floating-item item-3 desktop"
            src={itemsBottomR}
            alt=""
          />
          <img className="floating-item item-4 desktop" src={itemsL} alt="" />
          <img className="floating-item item-5 desktop" src={nikes} alt="" />
          <img className="floating-item item-6 desktop" src={eyeL} alt="" />
          <img className="floating-item item-7 desktop" src={duck} alt="" />

          <div className="header__main-lizard">
            <img
              className="header__main-creep"
              src={creep}
              alt="creep"
            />
            <Lottie className="right-eye" animationData={eye} loop={true} />
            <Lottie className="left-eye" animationData={eye} loop={true} />
            <Lottie className="right-hand" animationData={hand} loop={true} />
            <Lottie className="left-hand" animationData={hand} loop={true} />
          </div>

          <div className="header__main-map">
            <img src={globe} alt="" />
            {/* <div className="overlie creepHouse-cursor"></div>
            <div className="overlie shapeshiters-cursor"></div>
            <div className="overlie staking-cursor"></div>
            <div className="overlie lords-cursor"></div>
            <div className="overlie shards-cursor"></div>
            <div className="overlie reptilian-cursor"></div>
            <div className="overlie saloon-cursor"></div>
            <div className="overlie dimential-cursor"></div>
            <div className="overlie mystery-cursor"></div>
            <div className="overlie temple-cursor"></div> */}
          </div>
        </div>

        {/* <Button stroke="#fff" text="STAKE" extraClass="mobile stake-button"  onClick={!account ? activateWallet : () => {openStakingModal()}}/> */}
      </div>
    </header>
  );
};

export default Intro;
