import axios from 'axios';
import * as Sentry from "@sentry/react";
import { Config } from '../config';
import { gql } from "@apollo/client";


export const getUserData = async (address) => {
  try {
    const response = await axios.get(`${Config.apiLink}/users/${address.toLowerCase()}`)

    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const getUserTransactions = async (address) => {
  try {
    const response = await axios.get(`${Config.apiLink}/users/${address.toLowerCase()}/transactions`)

    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const transaferLeaderboardPoints = async (address, signature) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/transfer-leaderboard-points`,
      method: 'post',
      data: {
        address,
        signature
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const getUserYield = async (creepzIds, armouryIds, vaultIds) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/calculate-lamex-staking-rewards`,
      method: 'post',
      data: {
        creepzIds,
        armouryIds,
        vaultIds
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const getShardsYield = async (megaIds) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/calculate-mega-yield`,
      method: 'post',
      data: {
        megaIds
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const claimAccumulatedReward = async (address) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/users/${address.toLowerCase()}/refresh-lamex-staking-rewards`,
      method: 'post',
      data: {
        address
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const claimShards = async (address) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/users/${address.toLowerCase()}/refresh-shards-staking-rewards`,
      method: 'post',
      data: {
        address
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const USER_CHAIN_DATA = gql`
query User($ownerId: String!) {
  owners(where: {id: $ownerId}) {
    id
    hasSwitched
    lastMegaStake
    creeps(first: 1000, where: {isStaked: true}) {
      id
    }
    armouries(first: 1000, where: {isStaked: true}) {
      id
    }
    vaults(first: 1000, where: {isStaked: true}) {
      id
    }
    megaShapeShifters(first: 1000, where: {isStaked: true}) {
      id
    }
  }
}
`;
