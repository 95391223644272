import React, { useState } from 'react';
import {Config} from "../config";

const PopupContext = React.createContext(null);
PopupContext.displayName = 'PopupContext';

const PopupTexts = {
  metamaskError: {
    title: `Whoops!`,
    text: `Please install metamask or use the mobile Dapp browser!`,
    buttonText: `INSTALL METAMASK`,
    buttonType: `external`,
    buttonLink: `https://metamask.io/`
  },
  mintNotStarted: {
    title: `Sale is not active!`,
    text: `Try again or contact the team in discord.`,
    buttonText: `to the Creephouse`,
    buttonType: `external`,
    buttonLink: `http://www.discord.gg/ColdBloodedCreepz`
  },
  mintError: {
    title: `Mint Failed!`,
    text: `Please retry or contact the OVERLORD...`,
    buttonText: `Try Again`,
    buttonType: `internal`,
    buttonLink: ``
  },
  mintSuccess: {
    title: `Mint Successful!`,
    text: `You just summonded a Shapeshifter, naughty`,
    buttonText: `To opensea`,
    buttonType: `external`,
    buttonLink: `https://opensea.io/collection/creepz-shapeshifterz`
  },
  mintPending: {
    title: `Mint Pending!`,
    text: `The Shapes are putting on their human suits`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  notWhitelisted: {
    title: ``,
    text: `Your wallet is not whitelisted, come back later for public sale!`,
    buttonText: `to the Creephouse`,
    buttonType: `external`,
    buttonLink: `http://www.discord.gg/ColdBloodedCreepz`
  },
  notEnoughEther: {
    title: `Not enough Eth!`,
    text: `Please top up your wallet before trying again.`,
    buttonText: `Try Again`,
    buttonType: `internal`,
    buttonLink: ``
  },
  notEnoughLoomi: {
    title: `Not enough $loomi!`,
    text: `Go back and pillage earth for that sweeeeet loomi`,
    buttonText: `Try Again`,
    buttonType: `internal`,
    buttonLink: ``
  },
  shapeSoldOut: {
    title: `Sold out!`,
    text: `The Shapeshifters are already deployed`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  shapeNotStarted: {
    title: `Mint not active!`,
    text: `Please wait, the Shapes are not ready to party`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  noCreepz: {
    title: `You need a creepz!`,
    text: `This is a Genesis holder only drop, try again`,
    buttonText: `Get a Genesis Creep`,
    buttonType: `internal`,
    buttonLink: ``
  },
  shapeTimeLimit: {
    title: `You greedy creep!`,
    text: `You can only mint once in 24 hours. Come back later`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  armsNotStarted: {
    title: `Mint not active!`,
    text: `Please wait, the spy birds are naked`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  armsSoldOut: {
    title: `Sold out!`,
    text: `The factory has run out of Reptile Armoury `,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  maxOut: {
    title: `You sneaky lizard!`,
    text: `The overlord caught you trying to claim more than you have, try again.`,
    buttonText: `Try Again`,
    buttonType: `internal`,
    buttonLink: ``
  },
  overLimit: {
    title: `BIG LIZARD ENERGY!`,
    text: `You can only claim 10 (or less) at one time, you are not that cool.`,
    buttonText: `Try Again`,
    buttonType: `internal`,
    buttonLink: ``
  },
  wrongNetwork: {
    title: ``,
    text: `Change your network to ${Config.targetChain == "1" ? `Mainnet` : `Rinkeby`}`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  depositPending: {
    title: `Staking pending!`,
    text: `Your army is joining the invasion grounds!`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  depositSuccess: {
    title: `Staking complete!`,
    text: `Welcome to the invasion grounds you creep!`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  depositFailed: {
    title: `Staking Failed!`,
    text: `Please try again to stake your army!`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  withdrawPending: {
    title: `Un-staking pending!`,
    text: `Your army is leaving the invasion grounds!`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  withdrawSuccess: {
    title: `Un-staking complete!`,
    text: `You have left the invasion grounds!`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  withdrawFailed: {
    title: `Un-staking Failed!`,
    text: `Please try again to unstake your army!`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  stakingNotActive: {
    title: `Staking not active`,
    text: `The invasion will start soon, are you ready?`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  loomiWithdrawPending: {
    title: `Withdraw pending!`,
    text: `Please hold as the OVERLORD mints your loomi`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  loomiWithdrawSuccess: {
    title: `Withdraw success!`,
    text: `You have cashed out your loomi into an ERC-20`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  loomiWithdrawFailed: {
    title: `Withdraw failed!`,
    text: `Try again to withdraw your $loomi`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  loomiDepositPending: {
    title: `Deposit pending!`,
    text: `Wait as the OVERLORD loads your balance`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  loomiDepositSuccess: {
    title: `Deposit success!`,
    text: `You have added loomi to your in-game balance`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  loomiDepositFailed: {
    title: `Deposit failed!`,
    text: `Try again to add loomi to your in game balance`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  bankNotActive: {
    title: `Bank Closed!`,
    text: `Come back later, the OVERLORD is busy invading`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  pouchPending: {
    title: `Mint Pending!`,
    text: `The OVERLORD is crafting your vault. Be ready.`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  pouchSuccess: {
    title: ``,
    text: `Welcome to the OVERLORD’s private bank. 10,000 $loomi has been deposited in your account`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  pouchFailed: {
    title: `Mint Failed!`,
    text: `Please retry or contact the OVERLORD...`,
    buttonText: `Retry`,
    buttonType: `internal`,
    buttonLink: ``
  },
  pouchNotStarted: {
    title: `Mint not active!`,
    text: `Please wait, the private bank is not open yet.`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  },
  pouchSoldOut: {
    title: `Sold out!`,
    text: `The OVERLORD’s private bank is full of creepz`,
    buttonText: `Buy on Opensea`,
    buttonType: `external`,
    buttonLink: `https://opensea.io/collection/creepz-loomi-vault`
  },
  mysteryFinished: {
    title: `Mystery Box closed!`,
    text: `From now on it remains a mystery...`,
    buttonText: `Continue`,
    buttonType: `internal`,
    buttonLink: ``
  }
}

const PopupProvider = ({ children }) => {
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({});

  return (
    <PopupContext.Provider
      value={{
        alert: alert,
        alertContent: alertContent,
        show: (title, text, buttonText, buttonType, buttonLink) => {
          setAlertContent({
            title,
            text,
            buttonText,
            buttonType,
            buttonLink,
          });
          setAlert(true);
        },
        clear: () => (setAlert(false)),
        showMetamskError: () => {
          setAlertContent(PopupTexts.metamaskError);
          setAlert(true);
        },
        showMintNotStarted: () => {
          setAlertContent(PopupTexts.mintNotStarted);
          setAlert(true);
        },
        showWhitelistError: () => {
          setAlertContent(PopupTexts.notWhitelisted);
          setAlert(true);
        },
        showSuccessTx: () => {
          setAlertContent(PopupTexts.mintSuccess);
          setAlert(true);
        },
        showErrorTx: () => {
          setAlertContent(PopupTexts.mintError);
          setAlert(true);
        },
        showPendingTx: () => {
          setAlertContent(PopupTexts.mintPending);
          setAlert(true);
        },
        showMaxoutError: () => {
          setAlertContent(PopupTexts.maxOut);
          setAlert(true);
        },
        showNotEnoughEth: () => {
          setAlertContent(PopupTexts.notEnoughEther);
          setAlert(true);
        },
        showNotEnoughLoomi: () => {
          setAlertContent(PopupTexts.notEnoughLoomi);
          setAlert(true);
        },
        showTimeLimitError: () => {
          setAlertContent(PopupTexts.shapeTimeLimit);
          setAlert(true);
        },
        showCreepzError: () => {
          setAlertContent(PopupTexts.noCreepz);
          setAlert(true);
        },
        showOverLimit: () => {
          setAlertContent(PopupTexts.overLimit);
          setAlert(true);
        },
        showNetworkError: () => {
          setAlertContent(PopupTexts.wrongNetwork);
          setAlert(true);
        },
        showDepositPending: () => {
          setAlertContent(PopupTexts.depositPending);
          setAlert(true);
        },
        showDepositSuccess: () => {
          setAlertContent(PopupTexts.depositSuccess);
          setAlert(true);
        },
        showDepositFailed: () => {
          setAlertContent(PopupTexts.depositFailed);
          setAlert(true);
        },
        showWithdrawPending: () => {
          setAlertContent(PopupTexts.withdrawPending);
          setAlert(true);
        },
        showWithdrawSuccess: () => {
          setAlertContent(PopupTexts.withdrawSuccess);
          setAlert(true);
        },
        showWithdrawFailed: () => {
          setAlertContent(PopupTexts.withdrawFailed);
          setAlert(true);
        },
        showStakingNotActive: () => {
          setAlertContent(PopupTexts.stakingNotActive);
          setAlert(true);
        },
        showLoomiDepositError: () => {
          setAlertContent(PopupTexts.loomiDepositFailed);
          setAlert(true);
        },
        showLoomiDepositSuccess: () => {
          setAlertContent(PopupTexts.loomiDepositSuccess);
          setAlert(true);
        },
        showLoomiDepositPending: () => {
          setAlertContent(PopupTexts.loomiDepositPending);
          setAlert(true);
        },
        showLoomiWithdrawPending: () => {
          setAlertContent(PopupTexts.loomiWithdrawPending);
          setAlert(true);
        },
        showLoomiWithdrawError: () => {
          setAlertContent(PopupTexts.loomiWithdrawFailed);
          setAlert(true);
        },
        showLoomiWithdrawSuccess: () => {
          setAlertContent(PopupTexts.loomiWithdrawSuccess);
          setAlert(true);
        },
        showBankNotActive: () => {
          setAlertContent(PopupTexts.bankNotActive);
          setAlert(true);
        },
        showShapeMintNotStarted: () => {
          setAlertContent(PopupTexts.shapeNotStarted);
          setAlert(true);
        },
        showMintFinished: () => {
          setAlertContent(PopupTexts.armsSoldOut);
          setAlert(true);
        },
        showPouchPendingTx: () => {
          setAlertContent(PopupTexts.pouchPending);
          setAlert(true);
        },
        showPouchSuccessTx: () => {
          setAlertContent(PopupTexts.pouchSuccess);
          setAlert(true);
        },
        showPouchErrorTx: () => {
          setAlertContent(PopupTexts.pouchFailed);
          setAlert(true);
        },
        showPouchSoldOut: () => {
          setAlertContent(PopupTexts.pouchSoldOut);
          setAlert(true);
        },
        showPouchNotStarted: () => {
          setAlertContent(PopupTexts.pouchNotStarted);
          setAlert(true);
        },
        showShapeSoldOut: () => {
          setAlertContent(PopupTexts.shapeSoldOut);
          setAlert(true);
        },
        showMysteryFinished: () => {
          setAlertContent(PopupTexts.mysteryFinished);
          setAlert(true);
        }
        
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export { PopupProvider };
export default PopupContext;