import React, { useState } from 'react';
import {Config} from "../config";
import {getUserReward, getLamexBalance, getUserLamexId} from '../plugins/web3';
import {shortenAddress,getMethodName,getNormalisedDate} from '../plugins/utils';
import {getUserData,getUserTransactions} from '../plugins/lamexModel';
import {getUserDna, getUserTraits} from '../plugins/dnaModel';
import {getUserIDs} from '../plugins/evolutionModel';

const UserContext = React.createContext(null);
UserContext.displayName = 'UserContext';

const UserProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const [secondAddress, setSecondAddress] = useState('');
  const [loomiBalance, setLoomiBalance] = useState(0);
  const [lamexBalance, setLamexBalance] = useState(0);
  const [lamexTier, setLamexTier] = useState(0);
  const [stakedCreepz, setStakedCreepz] = useState([]);
  const [stakedArms, setStakedArms] = useState([]);
  const [stakedVaults, setStakedVaults] = useState([]);
  const [stakedMegas, setStakedMegas] = useState([]);
  const [isLamexOwner, setIsLamexOwner] = useState(false);
  const [hasTransferedPoints, setHasTransferedPoints] = useState(false);
  const [isYieldingLamex, setIsYieldingLamex] = useState(false);
  const [isDnaLocked, setIsDnaLocked] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [lastLamexStakingReward, setLastLamexStakingReward] = useState(0);
  const [lastShardStakingReward, setLastShardStakingReward] = useState(0);
  const [lamexYield, setLamexYield] = useState(0);
  const [creepzYield, setCreepzYield] = useState(0);
  const [shardsYield, setShardsYield] = useState(0);
  const [lamexId, setLamexId] = useState(null);
  const [nonce, setNonce] = useState(0);
  const [lastShardNonce, setLastShardNonce] = useState(0);
  const [shardBalance, setShardBalance] = useState(0);
  const [dnaScore, setDnaScore] = useState(0);
  const [hasOpenedPortal, setHasOpenedPortal] = useState(false);
  const [moleculeLockedDates, setMoleculeLockedDates] = useState([]);
  const [shouldDoubleNextDnaBoost, setShouldDoubleNextDnaBoost] = useState([]);
  const [idCount, setIdCount] = useState([]);
  const [whitelistLockedDates, setWhitelistLockedDates] = useState([]);
  const [traits, setTraits] = useState([]);
  const [packsPurchased, setPacksPurchased] = useState([]);
  const [packsLockedDates, setPacksLockDates] = useState([]);
  const [marketplaceLockedUntilDate, setMrketplaceLockedUntilDate] = useState({});
  const [idLockedUntilDate, setIdLockedUntilDate] = useState(0);
  const [discord, setDiscord] = useState("");
  const [idBackground, setIdBackground] = useState(window.localStorage.getItem('background') ? JSON.parse(window.localStorage.getItem('background')) : "");
  const [evolvedIDs, setEvolvedIDs] = useState([])
  
  return (
    <UserContext.Provider
      value={{
        username,
        loomiBalance,
        lamexBalance,
        isLamexOwner,
        stakedCreepz,
        stakedArms,
        stakedVaults,
        stakedMegas,
        hasTransferedPoints,
        isYieldingLamex,
        transactions,
        lamexTier,
        lastLamexStakingReward,
        lamexYield,
        shardsYield,
        lamexId,
        nonce,
        dnaScore,
        hasOpenedPortal,
        moleculeLockedDates,
        shouldDoubleNextDnaBoost,
        idCount,
        whitelistLockedDates,
        shardBalance,
        lastShardStakingReward,
        creepzYield,
        lastShardNonce,
        traits,
        packsPurchased,
        packsLockedDates,
        secondAddress,
        isDnaLocked,
        discord,
        setIsDnaLocked,
        marketplaceLockedUntilDate,
        idLockedUntilDate,
        idBackground,
        setIdBackground,
        evolvedIDs,
        setMainData: (data) => {
          setLoomiBalance(data.loomiBalance);
          setLamexBalance(data.lamexBalance);
          setIsLamexOwner(data.isLamexOwner);
          setHasTransferedPoints(data.hasTransferedPoints);
          setUsername(data.username);
          setTransactions(data.transactions)
          setLastLamexStakingReward(data.lastLamexStakingReward)
        },
        setGraphData: (data) => {
          console.log(`data.stakedVaults`, data)
          setStakedCreepz(data.stakedCreepz);
          setStakedArms(data.stakedArms);
          setStakedVaults(data.stakedVaults);
          setStakedMegas(data.stakedMegas);
          setIsYieldingLamex(data.isYieldingLamex);
          setLamexTier(data.lamexTier);
          setLamexYield(data.lamexYield);
          setShardsYield(data.shardsYield);
          setCreepzYield(data.creepzYield);

          setLastShardStakingReward(prev => {
            if (prev) {
              return new Date(prev) > new Date(data.lastShardStakingReward) ? prev : data.lastShardStakingReward;
            }
            return data.lastShardStakingReward;
          })
        },
        resetGraphData: () => {
          setStakedCreepz([]);
          setIsYieldingLamex(false);
          setLamexTier(0)
          setLamexYield(0)
        },
        updateUserData: async (account) => {
          const loomiBalance = await getUserReward(account);
          const isLamexHolder = await getLamexBalance(account);
          const userData = await getUserData(account);
          const userTransactions = await getUserTransactions(account);
          const dnaData = await getUserDna(account);

          let userLamexId = null;
          
          if (isLamexHolder) {
            try {
              userLamexId = await getUserLamexId(account);
            } catch (e) {
              console.log(e)
            }
            
          }

          const structuredTransactions = [];
          if (userTransactions) {
            userTransactions.data.forEach(x => {
              structuredTransactions.push({
                from: shortenAddress(x.sender),
                to: shortenAddress(x.receiver),
                value: `${x.functionType.includes(`BUY`) 
                  || x.functionType.includes(`CLAIM_WHITELIST`) 
                  || x.functionType.includes(`PURCHASE_PACK`) 
                  || x.functionType.includes(`PURCHASE_TRAIT`) 
                  ? `- ` : ``}${+x.value < 0.01 && +x.value !== 0  ? `<0.01` : +x.value.toFixed(2)} LAMEX`,
                method: getMethodName(x.functionType),
                age: getNormalisedDate(x.date)
              })
            })
          }

          setLoomiBalance(Math.floor(Number(loomiBalance)));
          setLamexBalance(userData?.data?.lamexBalance ? Math.floor(+userData.data.lamexBalance * 100) / 100 : 0);
          setIsLamexOwner(Boolean(+isLamexHolder));
          setLamexId(userLamexId);
          setHasTransferedPoints(userData ? userData?.data?.hasTransferredPoints : false);
          setUsername(userData?.data?.nickname ? userData.data.nickname : '');
          setTransactions(structuredTransactions)
          setLastLamexStakingReward(userData ? userData?.data?.lastLamexStakingReward : 0);
          setNonce(userData ? userData?.data?.lastLamexNonce : 0);
          setSecondAddress(userData ? userData?.data?.secondAddress : "");
          setMoleculeLockedDates(userData ? userData?.data?.moleculeLockedDates : []);
          setShouldDoubleNextDnaBoost(userData ? userData?.data?.shouldDoubleNextDnaBoost : []);
          setWhitelistLockedDates(userData ? userData?.data?.whitelistLockedDates : [])
          setPacksLockDates(userData ? userData?.data?.packsLockedDates : [])
          setShardBalance(userData ? userData?.data?.shardBalance : 0)
          setLastShardStakingReward(prev => {
            if (prev) {
              return new Date(prev) > new Date(userData?.data?.lastShardStakingReward) ? prev : userData?.data?.lastShardStakingReward;
            }
            return userData ? userData?.data?.lastShardStakingReward : 0;
          })
          setLastShardNonce(userData?.data?.lastShardNonce || 0)
          setMrketplaceLockedUntilDate(userData?.data?.marketplaceLockedUntilDate)
          setDiscord(userData?.data?.discord || "")
          
          if (dnaData?.status === 200) {
            setDnaScore(dnaData.data.score);
            setHasOpenedPortal(true)
            setIdCount(dnaData.data.lizardCount)
            setPacksPurchased(dnaData.data?.packsBought ?? []);
            setIsDnaLocked(!dnaData.data?.lockedUntilDate || new Date(dnaData.data?.lockedUntilDate) < new Date())
            setIdLockedUntilDate(dnaData.data?.lockedUntilDate || new Date(0))
          }
          
          return true;
        },
        getUserEvolvedIDs: async (account, idsToEvolve) => {
          const data = await getUserIDs(account);
          if (!data?.status === 200) {
            setEvolvedIDs(Array(idsToEvolve).fill(null));
            return;
          }

          const evolvedIds = [...data.data];
          if (evolvedIds.length < idsToEvolve) {
            for (let i = evolvedIds.length; i < idsToEvolve; i++) {
              evolvedIds.push(null);
            }
          }
          console.log(`evolvedIds`,evolvedIds)
          setEvolvedIDs(evolvedIds)
        }
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider };
export default UserContext;