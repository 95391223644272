import axios from 'axios';
import * as Sentry from "@sentry/react";
import { Config } from '../config';


export const getTraits = async (category) => {
  const categoryFilter = category ? `&category=${category}` : ``;
  try {
    const response = await axios.get(`${Config.apiLink}/traits/?isOnSale=true${categoryFilter}`)
    
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return {
        data: []
      };
  }
}

export const getSaleCategories = async () => {
  try {
    const response = await axios.get(`${Config.apiLink}/on-sale-categories`)

    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const getTrait = async (traitId) => {
  try {
    const response = await axios.get(`${Config.apiLink}/traits/${traitId}`)

    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const getPacks = async () => {
  try {
    const response = await axios.get(`${Config.apiLink}/packs`)

    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const purchaseTrait = async (address, traitId, signature, nonce, shardNonce, value, shardValue) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/traits/${traitId}/purchase`,
      method: 'post',
      data: {
        address,
        nonce,
        value,
        shardNonce,
        shardValue,
        signature
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const openPack = async (address, packId, signature, nonce, shardNonce, value, shardValue) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/packs/${packId}/purchase`,
      method: 'post',
      data: {
        address,
        nonce,
        value,
        shardNonce,
        shardValue,
        signature
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const burnTrait = async (address, traitId, nonce, signature) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/traits/${traitId}/burn`,
      method: 'post',
      data: {
        address,
        nonce,
        signature
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const updateTraitOrder = async (address, category, traits, signature) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/traits/${address}/update-traits-order`,
      method: 'patch',
      data: {
        category,
        traits,
        signature
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}


export const releaseDnaLock = async (address, signature, dateString) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/users/${address}/burn-excess-traits`,
      method: 'post',
      data: {
        signature,
        dateString
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}
