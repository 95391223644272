
   
import Web3 from 'web3';
import {lizardAbi} from '../assets/blockchain/contract';
import {shapeAbi} from '../assets/blockchain/shape';
import {armsAbi} from '../assets/blockchain/arms';
import {stakingAbi} from '../assets/blockchain/staking';
import {illiminartiAbi} from '../assets/blockchain/illu';
import {pouchAbi} from '../assets/blockchain/pouch';
import {megaAbi} from '../assets/blockchain/mega';
import {vaultStakingAbi} from '../assets/blockchain/vaultStaking';
import {mysteryAbi} from '../assets/blockchain/mystery';
import {lamexAbi} from '../assets/blockchain/lamex';
import {switchYieldAbi} from '../assets/blockchain/switchYield';

import {Config} from "../config";

export const web3 = new Web3(window.ethereum || Config.infuraUri);

export const lizardAddress = Config.contractAddress;
export const shapeAddress = Config.shapeAddress;
export const armsAddress = Config.armsAddress;
export const stakingAddress = Config.stakingAddress;
export const illiminartiAddress = Config.illiminartiAddress;
export const pouchAddress = Config.pouchAddress;
export const megaAddress = Config.megaAddress;
export const vaultStakingAddr = Config.vaultStakingAddress;
export const mysteryAddr = Config.mysteryAddress;
export const lamexAddr = Config.lamexAddress;
export const yieldAddr = Config.yieldSwitchAddress;

const lizardContract = new web3.eth.Contract(lizardAbi, lizardAddress);
const shapeContract = new web3.eth.Contract(shapeAbi, shapeAddress);
const armsContract = new web3.eth.Contract(armsAbi, armsAddress);
const stakingContract = new web3.eth.Contract(stakingAbi, stakingAddress);
const illuminartiContract = new web3.eth.Contract(illiminartiAbi, illiminartiAddress);
const pouchContract = new web3.eth.Contract(pouchAbi, pouchAddress);
const megaContract = new web3.eth.Contract(megaAbi, megaAddress);
const vaultStaking = new web3.eth.Contract(vaultStakingAbi, vaultStakingAddr);
const mysteryContract = new web3.eth.Contract(mysteryAbi, mysteryAddr);
const lamexContract = new web3.eth.Contract(lamexAbi, lamexAddr);
const yieldContract = new web3.eth.Contract(switchYieldAbi, yieldAddr);


// lamex

export const getLamexBalance = async (account) => {
  return await lamexContract.methods.balanceOf(account).call();
}

export const getUserLamexId = async (account) => {
  return await lamexContract.methods.tokenOfOwnerByIndex(account, '0').call();
}

export const hasUserSwitchedToLamex = async (account) => {
  return await yieldContract.methods.hasUserPortaled(account).call();
}

// Creepz

export const getCurrentCreepzSupply = async () => {
  return await shapeContract.methods.totalSupply().call();
}

export const isSaleActive = async () => {
  return await shapeContract.methods.saleIsActive().call();
}

export const toChecksumAddress = (address) => {
  return Web3.utils.toChecksumAddress(address);
}

// Creepz

export const checkUserBalance = async (address) => {
  const balance = await lizardContract.methods.balanceOf(address).call();
  return balance;
}

export const getPreSale = async () => {
  return await lizardContract.methods.preSaleActive().call();
}

export const getPublicSale = async () => {
  return await lizardContract.methods.publicSaleActive().call();
}

export const areCreepzApproved = async (account) => {
  return await lizardContract.methods.isApprovedForAll(account, stakingAddress).call();
}


// Staking

export const getStakedTokens = async (account) => {
  return await stakingContract.methods.getStakerTokens(account).call();
}

export const getUserYield = async (account) => {
  const userYield = await stakingContract.methods.getStakerYield(account).call();
  return web3.utils.fromWei(userYield);
}

export const getAcceleratedYield = async () => {
  const now = (Date.now()/1000).toFixed(0);
  const deadline =  await stakingContract.methods.acceleratedYield().call();
  return now < deadline;
}


// vault staking

export const getStakedVaultTokens = async (account) => {
  return await vaultStaking.methods.getStakerTokens(account).call();
}

export const getUserAccumulated = async (account) => {
  const acc =  await vaultStaking.methods.getAccumulatedAmount(account).call();
  return web3.utils.fromWei(acc);
}

export const getPriceChange = async (account) => {
  return await vaultStaking.methods.getPriceChange(account).call();
}

export const isVaultApproved = async (account) => {
  return await pouchContract.methods.isApprovedForAll(account, vaultStakingAddr).call();
}




// arms

export const getPurchesedTokens = async (account) => {
  return await shapeContract.methods._mintedByAddress(account).call();
}

export const areArmsApproved = async (account) => {
  return await armsContract.methods.isApprovedForAll(account, stakingAddress).call();
}

// illuminaty

export const getUserReward = async (account) => {
  const reward = await illuminartiContract.methods.getUserBalance(account).call();
  return web3.utils.fromWei(reward);
}

export const getUserBalance = async (account) => {
  const balance = await illuminartiContract.methods.balanceOf(account).call();
  return web3.utils.fromWei(balance);
}

// pouch

export const inPouchSaleActive = async () => {
  const isActive = await pouchContract.methods.saleIsActive().call();
  return isActive;
}

export const pouchedSold = async () => {
  return await pouchContract.methods.totalSupply().call();
}


// MEGA

export const getMegaBalance = async (account) => {
  return await megaContract.methods.balanceOf(account).call()
}

export const areMegasApproved = async (account) => {
  return await megaContract.methods.isApprovedForAll(account, stakingAddress).call();
}

// export const getUserPoints = async (account) => {
//   return await mysteryContract.methods.getUserBalance(account).call()
// }

export const getUserItems = async (account) => {
  return await mysteryContract.methods.getUserItems(account).call()
}

export const signData = async (address, username) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
    'address': address,
    'nickname': username,
  })), address, "");
  return signature;
}


export const signLeaderboardPointsTransfer = async (address) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
    'address': address.toLowerCase(),
    'code': 'LEADERBOARD_TRANSFER',
  })), address, "");
  return signature;
}


// dna signatures

export const signPortalOpening = async (address) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
    'address': address.toLowerCase(),
    'code': "OPEN_PORTAL",
  })), address, "");
  return signature;
}

export const signDnaBoost = async (address, value, nonce) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
    'address': address.toLowerCase(),
    'code': "BUY_DNA_BOOST",
    'value': value,
    'nonce': nonce,
})), address, "");
  return signature;
}

export const signMoleculeUsage = async (address, moleculeType, price, nonce) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
    address: address.toLowerCase(),
    code: "BUY_DNA_MOLECULE",
    type: moleculeType,
    price: price,
    nonce: nonce,
})), address, "");
  return signature;
}

export const signWhitelistUsage = async (address, secondAddress, whitelistId, price, nonce) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
      address: address.toLowerCase(),
      secondAddress: secondAddress?.toLowerCase(),
      code: "CLAIM_WHITELIST",
      whitelistId: whitelistId,
      price: price,
      nonce: nonce
})), address, "");
  return signature;
}

export const signTraitPurchase = async (address, traitId, nonce, shardNonce, value, shardValue) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
      address: address.toLowerCase(),
      code: "PURCHASE_TRAIT",
      traitId: traitId,
      price: value,
      nonce: nonce,
      shardPrice: shardValue,
      shardNonce: shardNonce,
})), address, "");
  return signature;
}

export const signTraitBurn = async (address, traitId, nonce) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
      address: address.toLowerCase(),
      code: "BURN_TRAIT",
      traitId: traitId,
      nonce: nonce,
})), address, "");
  return signature;
}

export const signPackPurchase = async (address, packId, nonce, shardNonce, value, shardValue) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
      address: address.toLowerCase(),
      code: "PURCHASE_PACK",
      packId: packId,
      price: value,
      nonce: nonce,
      shardPrice: shardValue,
      shardNonce: shardNonce,
})), address, "");
  return signature;
}

export const signTraitsPin = async (address) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
      address: address.toLowerCase(),
      code: "UPDATE_TRAITS_ORDER",
})), address, "");
  return signature;
}

export const signIdUnlock = async (address, dateString) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
      address: address.toLowerCase(),
      code: "BURN_EXCESS_TRAITS",
      dateString
})), address, "");
  return signature;
}

export const signIdCreation = async (address, name, traitsMap) => {
  let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(JSON.stringify({
    address: address.toLowerCase(),
    name,
    traitsMap,
    code: "CREATE_INTERDIMENSIONAL",
})), address, "");
  return signature;
}


