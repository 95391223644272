import { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { useContractFunction } from "@usedapp/core";
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import {NotificationManager} from 'react-notifications';

import {Config} from "../../../config";

import Dashline from '../Elements/Dashline.jsx';
import Text from '../Elements/Text';

import SectionContainerFlat from '../Elements/SectionContainerFlat';
import SwitchYield from './SwitchYield';
import StakingStats from './StakingStats';
import WhitelistCard from './WhitelistCard';
import TransactionTable from './TransactionTable';
import TopUpLamexComponent from './TopUpLamexComponent';
import LamexSidebar from './LamexSidebar';
import WhitelistDetails from './WhitelistDetails';

import {switchYieldAbi} from '../../../assets/blockchain/switchYield';

import {claimAccumulatedReward} from '../../../plugins/lamexModel';

import UserContext from '../../../context/UserContext';
import WhitelistsContext from '../../../context/WhitelistsContext';

const yieldInterface = new utils.Interface(switchYieldAbi);
const yieldContract = new Contract(Config.yieldSwitchAddress, yieldInterface);

const Lamex = ({
    account,
    activateWallet,
    setCurrentState
  }) => {
    const { state: toLoomiState, send: switchToLoomi } = useContractFunction(yieldContract, 'yieldLoomi', { transactionName: 'yieldLoomi' });
    const { state: toLamexState, send: switchToLamex } = useContractFunction(yieldContract, 'yieldLamex', { transactionName: 'yieldLamex' });

    const user = useContext(UserContext);
    const whitelists = useContext(WhitelistsContext);

    const [activeTab, setActiveTab] = useState(0);
    const [activeWhitelists, setActiveWhitelists] = useState([]);
    const [selectValue, setSelectValue] = useState(`Alpha`);
    const [tabs, setTabs] = useState([
      {
        text: `Your Account`,
        active: true,
        id: 0
      },
      {
        text: `LAMEX Staking`,
        active: false,
        id: 1,
      },
      {
        text: `LAMEX Alpha`,
        active: false,
        id: 2,
      },
      {
        text: `Experiences`,
        active: false,
        id: 3,
        disabled: true
      }
    ]);

    const lamexButtons = [
      {
        id: 0,
        title: `Top Up Lamex`,
        onClick: () => {triggerExtraTab(true, `Top Up Lamex`)}
      },
      {
        id: 1,
        title: `Switch To Loomi`,
        onClick: () => {triggerExtraTab(true, `Switch to $LOOMI`)}
      },
      {
        id: 2,
        title: `Switch To Lamex`,
        onClick: () => {triggerExtraTab(true, `Switch to LAMEX`)}
      },
      {
        id: 3,
        title: `Activate Lamex`,
        onClick: () => {setCurrentState(`application`);}
      },
      {
        id: 4,
        title: <Link to="/#creepz-staking" style={{color: `inherit`, textDecoration: `none`}}>Creepz/RA/MEGA STAKING</Link>,
        onClick: () => {}
      },
      {
        id: 5,
        title: <Link to="/#vault-staking" style={{color: `inherit`, textDecoration: `none`}}>VAULT STAKING</Link>,
        onClick: () => {}
      }
    ]

    const returnCurrentButtons = () => {
      let buttons = [lamexButtons[0]];

      if (!user.isLamexOwner) {
        buttons.push(lamexButtons[3]);
      }
      if (user.isYieldingLamex) {
        buttons.push(lamexButtons[1]);
      } else if (user.isLamexOwner && !user.isYieldingLamex) {
        buttons.push(lamexButtons[2]);
      }
      if (activeTab === 1) {
        buttons = [];
        buttons.push(lamexButtons[4]);
        buttons.push(lamexButtons[5]);
      };
      if (activeTab === 2) buttons = [];

      setCurrentButtons(buttons)
    }
    
    const switchYieldAction = async (toLoomi) => {
      if (toLoomi) {
        await claimAccumulatedReward(account);
        user.updateUserData(account);
        switchToLoomi();
        return;
      }
      if (user.lamexId === null) {
        NotificationManager.error('You need to mint a LAMEX first');
        return;
      }
      switchToLamex(user.lamexId);
    }
    const [currentButtons, setCurrentButtons] = useState([]);
    const [extraTabContent, setExtratabContent] = useState(<TopUpLamexComponent />);

    const openProjectDetails = (id) => {
      const activeWL = whitelists.data.find(x => x._id === id);
      setExtratabContent(<WhitelistDetails account={account} projectId={id} socials={[activeWL.twitterLink,activeWL.discordLink,activeWL.websiteLink]}/>)
      triggerExtraTab(true,activeWL.title);
    }

    const triggerExtraTab = (toOpen, action) => {
      if (action == `Top Up Lamex`) setExtratabContent(<TopUpLamexComponent setExtraTab={setExtraTab} />)
      if (action == `Switch to $LOOMI`) setExtratabContent(<SwitchYield isToLoomi={true} switchFunction={() => {switchYieldAction(true)}} />)
      if (action == `Switch to LAMEX`) setExtratabContent(<SwitchYield isToLoomi={false} switchFunction={() => {switchYieldAction(false)}} />)
      setExtraTab(prev => {
        return {
          ...prev,
          header: action,
          isOpened: toOpen,
        }
      })
    }

    const [extraTab, setExtraTab] = useState({
      header: ``,
      isOpened: false,
      onClick: () => triggerExtraTab(false)
    });

    const onClickSetActiveTab = (newTab) => {
      const activeWl = whitelists.data.filter(x => x.listingActive);
      if (newTab === 2 && user.lamexTier === 0) return NotificationManager.warning(`With this Lamex tier you're missing out on ${activeWl.length} whitelist opportunities.`)
      setExtraTab(prev => {
        return {
          ...prev,
          isOpened: false,
        }
      })
      setTabs(prev => prev.map(x => x.id === newTab ? {...x, active: true} : {...x, active: false}));
      setActiveTab(newTab);
    }

    const updateWlData = async () => {
      const whitelistData = await whitelists.updateWhitelistData(account);
      setActiveWhitelists(whitelistData.filter(x => !x.isCommunity));
    }

    const updateSelectValue = (e) => {
      const value = e.target.value;

      if (value == `Alpha`) setActiveWhitelists(whitelists.data.filter(x => !x.isCommunity));
      if (value == `Available To Me`) setActiveWhitelists(whitelists.data.filter(x => !x.isUserSubscribed && new Date(x.whitelistUntilDate) > new Date()));
      if (value == `Upcoming Mints`) setActiveWhitelists(() => {
        const filreredItems = whitelists.data.filter(x => new Date(x.mintDate) > new Date());
        filreredItems.sort((prevItem, currItem) => new Date(prevItem.mintDate) - new Date(currItem.mintDate))
        return filreredItems;
      });
      if (value == `Holder Projects`) setActiveWhitelists(whitelists.data.filter(x => x.isCommunity));
      if (value == `My Whitelists`) setActiveWhitelists(whitelists.data.filter(x => x.raffleResults.isWinner));
      
      setSelectValue(value);
    }


    useEffect(() => returnCurrentButtons(), [account, activeTab])
    useEffect(() => updateWlData(), [])
    
    useEffect(() => {
      const status = toLamexState.status;
      if (status === 'None') return;
      switch (status) {
        case 'Mining':
          NotificationManager.info("Processing your switch to LAMEX")
          break;
        case 'Success': 
          NotificationManager.success('Switch complete, welcome to LAMEX')
          user.setGraphData({
            ...user,
            isYieldingLamex: true
          })
          setExtraTab(false);
          break;
        case 'Exception': 
          if (toLamexState.errorMessage.includes('User denied transaction')) {
            break;
          } else if (toLamexState.errorMessage.includes('Activate your lamex first')) {
            NotificationManager.error('You need to mint a LAMEX first')
            break;
          } else {
            NotificationManager.error('Switch failed, please try again')
            break;
          }
      }
    }, [toLamexState])

    useEffect(() => {
      const status = toLoomiState.status;
      if (status === 'None') return;
      switch (status) {
        case 'Mining':
          NotificationManager.info("Processing your switch to $loomi!")
          break;
        case 'Success': 
          NotificationManager.success('Switched to yielding $loomi successfully!')
          user.setGraphData({
            ...user,
            isYieldingLamex: false
          })
          setExtraTab(false);
          break;
        case 'Exception': 
          if (toLoomiState.errorMessage.includes('User denied transaction')) {
            break;
          } else if (toLoomiState.errorMessage.includes('Switch cool down period')) {
            NotificationManager.error('You cannot switch between yielding $loomi and LAMEX too often, try again later!')
            break;
          } else {
            NotificationManager.error('Switch failed, please try again!')
            break;
          }
      }
    }, [toLoomiState])

    return (
      <>        
        <LamexSidebar account={account} activateWallet={activateWallet} />

        <div className="dashboard-content">
          <div className="content-menu"><Link to="/season-2" onClick={() => setCurrentState(`start`)}><span>BACK TO DASHBOARD</span></Link></div>
          {/* <div className="content-menu"><img src={menuIcon} alt=""/></div> */}
          <div className="content-body">
          <SectionContainerFlat
            tabs={tabs}
            setTab={onClickSetActiveTab}
            extraTab={extraTab}
            extraTabContent={extraTabContent}
            bottomButtons={currentButtons}
          >
            {
              activeTab === 0 ? (
                <>
                    <Text textType='header' content={`Welcome to your account${user.username ? `, ${user.username}!` : `!`}`}/>
                    <Dashline />
                    <Text content={"LAMEX Transaction History"}/>
                    <div className="lamex-table">
                      <TransactionTable data={user.transactions} />
                    </div>              
                </> 
              ) 
              : activeTab === 1 ? 
              (<>
                <StakingStats />
              </>)
              : activeTab === 2 ? (
                <>  
                    <div className="title-select-container">
                      <Text textType='header' content={`LAMEX alpha opportunities`}/>
                      <div style={{width: `180px`}}>
                        <select onChange={(e) => {updateSelectValue(e)}} className="custom-select" value={selectValue}>
                          <option value="Alpha">Alpha</option>
                          <option value="Holder Projects">Holder Projects</option>
                          <option value="Available To Me">Available To Me</option>
                          <option value="Upcoming Mints">Upcoming Mints</option>
                          <option value="My Whitelists">My Whitelists</option>
                        </select>
                      </div>
                    </div>
                    <Dashline />
                    <div className="whitelist-container">
                      {
                        activeWhitelists.length > 0 ?
                        activeWhitelists.map(x => {
                          if (x.listingActive) {
                            return (
                              <WhitelistCard 
                                projectInfo={x}
                                onClick={() => {openProjectDetails(x._id)}}
                              />
                            )
                          }
                        }): `No listings in this section`
                      }
                    </div>
                    
                </> 
              ) : ``
            }
          </SectionContainerFlat>
        </div>
      </div>
    </>
  )
}

export default Lamex;
