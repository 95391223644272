
import table1 from '../../assets/wp/table-1.png';
import table2 from '../../assets/wp/table-2.png';
import table3 from '../../assets/wp/table-3.png';
import table4 from '../../assets/wp/table-4.png';
import table5 from '../../assets/wp/table-5.png';
import table6 from '../../assets/wp/table-6.png';
import table7 from '../../assets/wp/table-7.png';
import table8 from '../../assets/wp/table-8.png';
import tableBribe from '../../assets/wp/tableBribe.png';
import tablePrizes from '../../assets/wp/tablePrizes.png';

import icon from '../../assets/wp/icon.svg';

const ModalLarge = ({setActiveModal}) => {

    return (
      <div
      onClick={(e) => {}}
      className={`popup project__info`}
       >
      <div className="popup__panel project__info">
        <div onClick={() => setActiveModal(false)} className="popup__button-close">
            <div className="wrapper"/>
        </div>

        <div className="popup__panel-wrapper">
        <div className="popup_text_container">
          {/* <Lottie className="image" animationData={gif1} style={{width: 145}}/> */}
          <img src={icon} className="icon" />
          <div className="popup__title">
            the comprehensive guide to the invasion of earth: Season 1
          </div>
          <div className="dash_line"></div>

          <div className="popup__text center">
          V3 [05.02.2022]<br />SPIN IT TO WIN IT
          </div>
          <br/>
          <div className="popup__text center alien__text">
            If you don’t read this, and we’ve spent all this time writing it then fuck you.
          </div>

          <div className="dash_line"></div>

          <div className="popup__subtitle">
            The TLDR
          </div>

          <div className="popup__text">
          • Creepz is a multi-drop ecosystem, earn $loomi to access art & experiences
            <br/>
            <br/>

            • Multi game map, follow the OVERLORD through the invasion games
            <br/>
            <br/>

            • Purchase upgrades, build an army and increase your yield 
            <br/>
            <br/>

            • Bribe the OVERLORD to withdraw to an ERC-20 or build your army
            <br/>
            <br/>

            • Sacrifice to the OVERLORD to progress to the otherside
            <br/>
            <br/>

            • Membership Club to build Creepz into a global mega-brand
            <br/>
            <br/>
            
            • Stay on your tails, things will change, dont sleep on Creepz
          </div>

          <div className="dash_line"></div>

          <div className="popup__subtitle">
          The Invasion Lore
          </div>
          
          <div className="popup__text">
          The time is now, Creepz are ready for the invasion. The OVERLORD, a mutant lizard from 1bn lizard years away has declared siege on Earth; capturing its land, seizing its ice-cream, milking its fortune and taking control of its people. 
          <br/>
          <br/>
          You play the role of a Creepz, the coolest bad guys in the galaxy. There are six breeds of Creepz, with varying strengths and skills - Collect them all, earn $loomi, upgrade your army and master the invasion.
          <br/>
          <br/>
          Stake your Creepz army in the invasion to yield $loomi. Bribe the Shapeshifters to bank your loot, or build your creep cred to keep it all.

          </div>

          {/* <div className="dash_line"></div>

          <div className="popup__subtitle">
          Minting Defi Collectables

          </div>

          <div className="popup__text">
          Cold Blooded Creepz Season 1 Defi Collectibles are the conspiracy kings (& queens) that besides having great style, have unrivalled utility. 
          </div>

          <img className="table" src={table6} /> */}

          <div className="dash_line"></div>

          <div className="popup__subtitle">
          Genesis Creepz 
          </div>

          <div className="popup__text">
          There are six breeds of lizards, each with their own skills in the Invasion that relate directly to their $loomi yield.
          <br/>
          <br/>
          By staking your Creepz into the invasion you yield $loomi. All staked Creepz will earn 2x the daily $loomi yield for the first 48 hours of the start of the staking game. Our aim is for all staked creepz owners to be able to access all future drops for free with the claimed $loomi.
          </div>

          <img className="table" src={table8} />

          <img className="table" src={table1} />



          <div className="dash_line"></div>

          <div className="popup__subtitle">
          Reptile Armoury
          </div>

          <div className="popup__text">
          Use your in game $loomi to upgrade your lizard army and increase your yield. You need to stake a Creepz to earn yield from the Reptile Armoury. The more you add the more your yield increases.
          <br/>
          <br/>
          The first 3 you mint will be 3000 $loomi. For every reptile armoury after this you will have to pay intergalactic scavengers to source you lemurian crystals and metals for the factory, as a result they will become 1000 $loomi more expensive every time. You will be able to autostake them direct into the invasion grounds to start yielding straight away.
          <br/>
          <br/>
          There are 5 types of NFT’s per collection. Each vary in rarity of occurrence. 
          </div>

          <img className="table" src={table2} />

          <img className="table" src={table3} />

          <div className="dash_line"></div>

          <div className="popup__subtitle">
          Shapeshifters
          </div>

          <div className="popup__text">
            Shapeshifters are the OVELORDS loyal disciples who keep order on earth. There are 5 variations of each shapeshifter character. Collect all 5 of a single character to sacrifice (burn) them to the Overlord. In return the OVERLORD will mutate your characters into a ‘mega-rare’ animated NFT of that character.
            <br/>
            <br/>
            These mega’s are the only shapeshifters that earn a % of bribes from the Creepz. There are a limited total number of each character, so collect them strategically from the shapeshifter mint to earn the mega-rares.
          </div>

          <img className="table" src={table7} />

          <img className="table" src={table4} />


          <div className="dash_line"></div>

          <div className="popup__subtitle">
            Mega Shapeshifters bribe claim 
          </div>

          <div className="popup__text">
            Through mutating your Shapeshifters into a MEGA you can begin claiming from the daily bribe pot. You will have 30 day guarenteed bribes of 450 $loomi per day + a dynamic bribe amount based on the 25% withdrawal bribe taken by the OVERLORD.
            <br />
            You can claim this daily, however, you do not need to.
          </div>

          <img className="table" src={tableBribe} />

          <div className="dash_line"></div>

          <div className="popup__subtitle">
            Mystery Box
          </div>

          <div className="popup__text">
            The (not so) Mystery Box is a moment or risk & reward. Spin the Mystery Box for ancient OVERLORD artefacts. The artefacts give you points on the leaderboard and can also unveil a “Shard”. Collect all 3 (1 per week) and unlock the inter-dimensional portal and access the next Creepz generative project. 
              <br/>
              <br/>
            As not all artefacts are made equal there is an element of risk involved. Some of the artefacts add points, some remove points and others do nothing. Spin at your own risk. 
              <br/>
              <br/>
            Each week, for 3 weeks, the artefacts will change and so will their points. You can spin the box as many times as you would like, in the race to climb.
              <br/>
              <br/>
            The artefacts are stored on-chain, however, are not NFT’s and therefore cannot be traded.  
          </div>

          <div className="dash_line"></div>

          <div className="popup__subtitle">
            Big Lizard Leaderboard
          </div>

          <div className="popup__text">
            As the grand finale for Season 1 approaches, the OVERLORD wants to know just who is the most loyal. The Big Lizard Leaderboard is just that. You can climb the leaderboard in 2 ways. 
            <br/>
            <br/>
            1. No Risk: Sacrifice $loomi directly to the OVERLORD. 1 point = 3000 $loomi
            <br/>
            <br/>
            2. Risk /  Reward: Spin the Mystery Box and hope you get a lucky artefact
            <br/>
            <br/>
            The Big Lizard Leaderboard will run for 3 weeks from Sunday 6th Feb @ 14:30 EST. At the end date of Sunday 26th Feb @ 14:30 EST the points will be counted. 
            <br/>
            <br/>
            The prize categories & prizes for those on the leaderboard are below. Prizes are raffled, 1 prize per rank lounge. 1st, 2nd & 3rd prizes guaranteed.
          </div>

          <img className="table" src={tablePrizes} />

          <div className="dash_line"></div>

          <div className="popup__subtitle">
          The $loomi Economy 
          </div>

          <div className="popup__text">
            $loomi is predominantly an in-game token for you to access the wider ecosystem - this includes future art drops, experiences & events. 
            <br/>
            <br/>
            We have developed an innovative in-game wallet that reduces the need for you to “claim” your staking rewards before you can use them. This innovation on L1 ETH saves the majority of gas costs that other p2e games have from constant claiming. Making the Creepz ecosystem more cost efficient, dynamic and fun. 
            <br/>
            <br/>
            $loomi can also be withdrawn from your in-game wallet into an ERC-20 token (also $loomi). You can withdraw loomi into an ERC-20 if you would like to transfer it to another wallet. You will, however, need to re-deposit Loomi to your in-game wallet before you can use it in the ecosystem.
            <br/>
            <br/>
            This is step one towards our commitment to technological innovation, alongside our continued work on L2 & side chain solutions.
          </div>

          <img className="table" src={table5} />


          <div className="dash_line"></div>

          <div className="popup__subtitle">
            Creepz Membership Club
          </div>

          <div className="popup__text">
          Creepz is on a mission to become a globally recognised brand, oh and flip BAYC. As part of this mission a Membership Club will be formed for all of the OVERLORDS loyal servants. Your ranking in the club will be dependent on just how loyal you are...
          <br/><br/>
          Building the Creepz ecosystem
          <br/><br/>
          • Grow Creepz into a globally recognised mega brand. Creepz {`>`} BAYC {`>`} MOON<br/>
          • Market first partnerships and sponsorships
          <br/><br/>
          Rewarding the Creepz community
          <br/><br/>
          • Rewarding the community through ridiculous experiences<br/>
          • Build a real Creepz fashion brand, not a “lizard on t-shirt” merch line
          <br/><br/>
          Full membership club tiering and information to be shared.
          <br/><br/><br/>

          <div className="dash_line"></div>

          <div className="popup__text">
            To join the invasion now, join our <a className="popup__text" href="https://discord.gg/overlordxyz">Creephouse discord here</a>
          </div>
          
          </div>


        </div>
              
        </div>
      </div>
       
      </div>
    )
}

export default ModalLarge;


