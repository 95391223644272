import axios from 'axios';
import * as Sentry from "@sentry/react";
import { Config } from '../config';


export const getMolecules = async () => {
  try {
    const response = await axios.get(`${Config.apiLink}/molecules`)

    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const getUserDna = async (address) => {
  try {
    const response = await axios.get(`${Config.apiLink}/users/${address.toLowerCase()}/dna`)

    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const getUserTraits = async (address) => {
  try {
    const response = await axios.get(`${Config.apiLink}/users/${address.toLowerCase()}/dna/traits`)

    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const boostDna = async (address, signature, nonce, value) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/users/${address}/buy-dna-boost`,
      method: 'post',
      data: {
        nonce,
        value,
        signature
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const buyMolecule = async (address, moleculeType, price, nonce, signature) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/users/${address}/buy-dna-molecule`,
      method: 'post',
      data: {
        nonce,
        moleculeType,
        price,
        signature,
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}

export const openPortal = async (address, signature) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/users/${address.toLowerCase()}/open-portal`,
      method: 'post',
      data: {
        signature
      }
    })
    return response;
  } catch (err) {
      try {
          Sentry.captureException(err);
      } catch (err) {
          console.error(err);
      }
      console.error(err);
      return err.response.data;
  }
}


