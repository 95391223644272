const Button = ({buttonText, background = `#452B6E`, border = `#101820`, color = `#64F7F7`, width = `100%`, onClick, extraClass, style }) => {

  return (
    <div className={`button ${extraClass ? extraClass : ``}`} style={{
      background: background,
      border: `1.75px solid ${border}`,
      color,
      width,
      ...style
    }} onClick={onClick}>{buttonText}</div>
  )
}

export default Button;