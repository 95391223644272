import moment from 'moment';

import { cards } from '../components/Dashboard/Data/LamexData';

export const shortenAddress = (account) => {
  return (`${account.slice(0, 6)}...${account.slice(
    account.length - 6,
    account.length
  )}`)
}

export const getCurrentLamexTier = (stakedCreepz, hasSwitched) => {
  if (!hasSwitched) return 0;
  return getFutureLamexTier(stakedCreepz);
}

export const getFutureLamexTier = (stakedCreepz) => {
  let tier = 0;

  if (stakedCreepz > 0 && stakedCreepz <= 5) tier = 1;
  if (stakedCreepz > 5 && stakedCreepz <= 10) tier = 2;
  if (stakedCreepz > 10 && stakedCreepz <= 16) tier = 3;
  if (stakedCreepz > 16 && stakedCreepz <= 24) tier = 4;
  if (stakedCreepz > 24) tier = 5;

  return tier;
}

export const getMethodName = (functionName) => {
  if (functionName === `LEADERBOARD_TRANSFER`) return `Account Opening`
  if (functionName === `USER_DEPOSIT`) return `Deposit`
  if (functionName === `STAKING_REWARD`) return `Staking Reward`
  if (functionName === `ADMIN_TRANSFER_TO_USER`) return `Points Transfer`
  if (functionName === `BUY_DNA_MOLECULE`) return `Molecule Spin`
  if (functionName === `BUY_DNA_BOOST`) return `ID Boost`
  if (functionName === `CLAIM_WHITELIST`) return `Alpha Sign Up`
  if (functionName === `BURN_TRAIT` || functionName === `TRAIT_BURN`) return `Trait Burn`
  if (functionName === `PURCHASE_TRAIT`) return `Marketplace Purchase`
  if (functionName === `PURCHASE_PACK`) return `Pack Purchase`
}

export const getUserIdLevel = (idScore) => {
  let level = 0;

  if (idScore >= 0 && idScore <= 4) level = 0;
  if (idScore > 4 && idScore <= 14) level = 1;
  if (idScore > 14 && idScore <= 34) level = 2;
  if (idScore > 34 && idScore <= 84) level = 3;
  if (idScore > 84 && idScore <= 184) level = 4;
  if (idScore > 184 && idScore <= 384) level = 5;
  if (idScore > 384 && idScore <= 1634) level = 6;
  if (idScore > 1634 && idScore <= 4134) level = 7;
  if (idScore > 4134 && idScore <= 9134) level = 8;
  if (idScore > 9134 && idScore <= 19134) level = 9;
  if (idScore >= 19134) level = 10;

  return level;
}

export const getWLSpotsByLevel = (entries, entriesTaken) => {
  const availablePerTier = [];

  for (let i = 0; i < entries.length; i++) {
    const additionalPoints = i === 0 ? 0 : availablePerTier[i-1];
    const value = entries[i] - entriesTaken[i].length + additionalPoints;
    availablePerTier.push(value);
  }

  return availablePerTier;
}

export const getNormalisedDate = (incomingDate) => {
  return moment(incomingDate).fromNow();
}

export const getFutureDate = (incomindDate) => {
  return moment(incomindDate).fromNow();
}

export const getUserLamex = (lamexTier) => {
  return cards[lamexTier];
}

export const normalizeDate = (date) => {
  return moment(date).format("MMM Do");
}