import React, { useContext, useState } from 'react';
import { getWhitelists } from '../plugins/whitelistModel';
import { getUserData } from '../plugins/lamexModel';

const WhitelistsContext = React.createContext(null);
WhitelistsContext.displayName = 'WhitelistsContext';

const WhitelistsProvider = ({ children }) => {
  const [data, setData] = useState([]);

  return (
    <WhitelistsContext.Provider
      value={{
        data,
        updateWhitelistData: async (address) => {
          const whitelists = await getWhitelists();
          const user = await getUserData(address);

          const returnIfLocked = (whitelistId) => {
            const lockInfo = user.data?.whitelistLockedDates.find(x => x.whitelistId === whitelistId);

            if (!lockInfo) return {
              isLocked: false
            };
            
            return {
              isLocked: new Date(lockInfo.lockDate) > new Date(),
              lockedUntil: lockInfo.lockDate
            }
          }

          let newData = whitelists.data.map(x => {
            console.log(!!x?.winners?.length)
            return {
              ...x,
              isUserSubscribed: x?.tierEntriesTaken?.some(x => x?.includes(address.toLowerCase()) 
                || x?.some(y => y.user === address.toLowerCase()))
                || x?.takenEntries?.some(y => y.user === address.toLowerCase()),
              raffleResults: {
                isRaffled: !!x?.winners?.length,
                isWinner: x?.winners?.length && x?.takenEntries?.some(y => y.user === address.toLowerCase()) ? x.winners.includes(address.toLowerCase()) : null,
                winners: x?.winners || []
              }
            }
          })

          newData = newData.reverse()
          setData(newData)
          return newData;
        }
      }}
    >
      {children}
    </WhitelistsContext.Provider>
  );
};

export { WhitelistsProvider };
export default WhitelistsContext;