import { useState } from "react";
const Button = ({color = "#9B5DDC", hover = "#9B5DDC", text, onClick, stroke = "#000", extraClass, style}) => {
  const [currentColor, setCurrentColor] = useState(color);

  return (
    <div className={`button-container ${extraClass}`} onMouseEnter={() => setCurrentColor(hover)} onMouseLeave={() => setCurrentColor(color)} onClick={onClick} style={style}>
      <div className="button-title" style={{color: stroke}}>{text}</div>
      <svg width="248" height="60" viewBox="0 0 248 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.7" d="M3.59644 7.83708C2.99548 6.43486 4.02405 4.875 5.54962 4.875H243.671C245.155 4.875 246.182 6.35691 245.661 7.74614L237.076 30.6394C236.747 31.5166 236.747 32.4834 237.076 33.3606L245.661 56.2539C246.182 57.6431 245.155 59.125 243.671 59.125H5.54962C4.02405 59.125 2.99548 57.5651 3.59643 56.1629L13.2978 33.5264C13.7155 32.5517 13.7155 31.4483 13.2978 30.4736L3.59644 7.83708Z" fill="#101820" stroke="#101820" stroke-width="1.75"/>
        <path d="M3.19383 5.231C2.31008 3.16891 3.82267 0.875 6.06616 0.875H241.228C243.41 0.875 244.92 3.05429 244.154 5.09726L236.207 26.2883C235.794 27.3919 235.794 28.6081 236.207 29.7117L244.154 50.9027C244.92 52.9457 243.41 55.125 241.228 55.125H6.06617C3.82268 55.125 2.31008 52.8311 3.19383 50.769L12.129 29.9204C12.6545 28.6941 12.6545 27.3059 12.129 26.0796L3.19383 5.231Z" fill={currentColor} stroke={stroke} stroke-width="1.75"/>
      </svg>
    </div> 
  )
}

export default Button;
